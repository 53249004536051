import { Component, ViewChild, TemplateRef } from '@angular/core'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'

@Component({
  selector: 'app-state-select-company-template-wrapper',
  template: `
    <ng-template #modal>
      <ng-content></ng-content>
    </ng-template>
  `,
  styles: [``]
})
export class StateSelectCompanyTemplateWrapperComponent {
  @ViewChild('modal') modalTemplate: TemplateRef<BsModalRef>
  private reference: BsModalRef

  modalWindowConfig = {
    animated: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-dialog-centered'
  }

  constructor(private modalService: BsModalService) {}

  show() {
    this.reference = this.modalService.show(this.modalTemplate, this.modalWindowConfig)
  }

  hide() {
    if (this.reference) {
      this.reference.hide()
    }
  }
}
