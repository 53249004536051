import { Component, Inject, InjectionToken, OnInit, Optional, PLATFORM_ID } from '@angular/core'
import { isPlatformBrowser, isPlatformServer } from '@angular/common'
import { RESPONSE } from '@nguniversal/express-engine/tokens'
import { Response } from 'express'
import { Engine } from "tsparticles-engine";
import { loadSlim } from "tsparticles-slim";

@Component({
  selector: 'app-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss']
})
export class NotFoundPageComponent implements OnInit {
  myStyle: object = {}
  particlesOptions: object = {}
  isBrowserWindow = false

  constructor(
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Record<string, unknown>>,
    @Optional() @Inject(RESPONSE) private response: Response
  ) {
    this.isBrowserWindow = isPlatformBrowser(this.platformId)
  }

  ngOnInit() {
    if (isPlatformServer(this.platformId)) {
      this.response.status(404)
    }

    this.myStyle = {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    }

    this.particlesOptions = {
      autoPlay: true,
      detectRetina: true,
      duration: 0,
      fpsLimit: 60,
      interactivity: {
        detectsOn: 'parent',
        events: {
          onHover: {
            enable: true,
            mode: 'grab'
          },
          resize: true
        },
        modes: {
          connect: {
            distance: 250,
            links: {
              opacity: 0.3
            },
            radius: 100
          },
          grab: {
            distance: 200,
            links: {
              opacity: 0.75
            }
          }
        }
      },
      particles: {
        color: {
          value: '#ffffff'
        },
        links: {
          blink: false,
          color: {
            value: '#ffffff'
          },
          consent: false,
          distance: 150,
          enable: true,
          frequency: 1,
          opacity: 0.15,
          width: 2,
          warp: false
        },
        move: {
          angle: {
            offset: 0,
            value: 90
          },
          enable: true,
          speed: 1
        },
        number: {
          density: {
            enable: true,
            area: 800,
            factor: 1000
          },
          limit: 0,
          value: 100
        },
        opacity: {
          random: {
            enable: true,
            minimumValue: 0.3
          },
          value: {
            min: 0.2,
            max: 0.6
          },
          animation: {
            count: 0,
            enable: true,
            speed: 0.5,
            sync: false,
            destroy: 'none',
            minimumValue: 0.3,
            startValue: 'random'
          }
        },
        reduceDuplicates: false,
        shape: {
          options: {},
          type: 'circle'
        }
      },
      pauseOnBlur: true,
      pauseOnOutsideViewport: true,
      responsive: [],
      fullScreen: false
    }
  }

  async particlesInit(engine: Engine): Promise<void> {
    await loadSlim(engine)
  }
}
