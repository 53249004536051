<div class="row">
  <div class="col-md-12 d-flex justify-content-center align-items-center header text-center">
    <ng-particles
      aria-hidden="true"
      *ngIf="isBrowserWindow"
      id="tsparticles-notfound"
      [style]="myStyle"
      [particlesInit]="particlesInit"
      [options]="particlesOptions"
    ></ng-particles>
    <section>
      <h1 class="display-1">404</h1>
      <h3>Seite nicht gefunden</h3>
    </section>
  </div>
</div>
<section class="row my-5">
  <div class="col-md-2"></div>
  <div class="col-xs-12 col-md-8 text-center">
    <p class="lead">Die angeforderte Seite konnte nicht gefunden werden.</p>
    <p class="mb-5">Möglicherweise ist die Stellenanzeige nicht mehr verfügbar oder die Adresse der Seite nicht korrekt.</p>
    <a class="btn btn-secondary" routerLink="/" data-test="not-found-button">Weiter zur Startseite</a>
  </div>
</section>
