import { Inject, Injectable, InjectionToken, PLATFORM_ID } from "@angular/core";
import { BehaviorSubject, Observable, tap } from "rxjs";
import { User } from '../../data/types'
import { WordpressService } from "../wordpress.service";
import { CookieConsentService } from "../cookie-consent.service";
import { isPlatformBrowser } from "@angular/common";
import { take } from "rxjs/operators";
import { MatomoTracker } from "@ngx-matomo/tracker";

@Injectable({ providedIn: 'root' })
export class UserService {
  public userSubject = new BehaviorSubject<User>(null)
  public $user = this.userSubject.asObservable()

  private localStorageKey = 'user'

  constructor(
    private wordpressService: WordpressService,
    private cookieConsentService: CookieConsentService,
    private readonly tracker: MatomoTracker,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Record<string, unknown>>
  ) {}

  initializeUser(): boolean {
    if (!isPlatformBrowser(this.platformId)) {
      return true
    }

    try {
      const lsValue = localStorage.getItem(this.localStorageKey)
      if (lsValue) {
        const localStorageUser = JSON.parse(lsValue)
        this.updateUser(localStorageUser)
        this.wordpressService.isTokenValid(localStorageUser.token).pipe(take(1)).subscribe({
          next: () => {},
          error: () => {
            this.logout()
            return false
          }
        })
      } else {
        this.logout( false)
        return false
      }
    } catch (e) {
      // NOP
    }

    return true
  }

  login(username: string, password: string): Observable<User> {
    return this.wordpressService.loginUser(username, password).pipe(
      tap((user) => {
        this.cookieConsentService.setCookiesAllowed()
        this.setUser(user)
        return user
      })
    )
  }

  private setUser(user: User) {
    this.updateUser(user)
  }

  logout(clearLs = true) {
    if (clearLs && isPlatformBrowser(this.platformId)) {
      try {
        localStorage.removeItem(this.localStorageKey)
      } catch (e) {
        // NOP
      }
    }
    this.updateUser(null)
  }

  public updateUser(user: User | null) {
    if (isPlatformBrowser(this.platformId) && localStorage.getItem('jb_pp') === '1') {
      if (user && user.email) {
        this.tracker.setUserId(user.email)
      } else {
        this.tracker.resetUserId()
      }
      try {
        localStorage.setItem(this.localStorageKey, JSON.stringify(user))
      } catch (e) {
        console.error('Failed to set local storage data', e)
      }
    }
    this.userSubject.next(user)
  }
}
