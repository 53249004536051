<div #headContainer class="headContainer fixed-top d-none d-sm-flex align-items-center justify-content-center">
  <form class="row" [formGroup]="searchForm">
    <div class="mr-2">
      <input
        type="text"
        class="form-control border rounded"
        id="what"
        placeholder="Jobtitel oder Stichwort"
        formControlName="searchString"
        (keydown.enter)="search()"
      />
    </div>
    <div>
      <select data-test="region-select" class="form-control region-select" aria-label="Region" name="region" formControlName="regionId" (change)="updateRegion($event.target.value)">
        <option *ngFor="let region of regions" [value]="region.id">
          {{ region.label }}
        </option>
      </select>
    </div>
    <a rel="nofollow" class="btn btn-secondary ml-2" (click)="search()" href="javascript:void(0)"><span class="fa fa-search mr-2"></span>Suchen</a>
  </form>
</div>

<form [formGroup]="searchForm">
  <div
    #headContainerMobile
    id="headContainerMobile"
    class="headContainer d-flex flex-column d-sm-none align-items-stretch justify-content-center p-2"
  >
    <div>
      <input
        type="text"
        class="form-control border rounded"
        placeholder="Jobtitel oder Stichwort"
        formControlName="searchString"
      />
    </div>
    <div class="my-1">
      <select class="form-control" name="region" aria-label="Region" formControlName="regionId" (change)="updateRegion($event.target.value)">
        <option *ngFor="let region of regions" [value]="region.id">
          {{ region.label }}
        </option>
      </select>
    </div>
    <a rel="nofollow" class="btn btn-secondary" (click)="search()" href="javascript:void(0)"><span class="fa fa-search mr-2"></span>Suchen</a>
  </div>
</form>
