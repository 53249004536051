import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  heroHeightSubject = new BehaviorSubject<string>('0px')
  heroHeight$ = this.heroHeightSubject.asObservable()
}
