<div id="companyNavigation" [class.slide-in]="showCompanyNavigation" [class.slide-out]="!showCompanyNavigation && userInteraction">
  <ul class="nav no-style navbar-nav mx-md-auto d-flex justify-content-center">
    <li (click)="onNavigationClick()" class="nav-item admin">
      <a class="nav-link px-3 py-2" [routerLink]="_company">Stellenanzeigen / Flatrates</a>
    </li>
    <li *ngIf="userHasMultipleCompanies" (click)="onNavigationClick()" class="nav-item admin">
      <a class="nav-link px-3 py-2" [routerLink]="['/', _company, _select]">Unternehmen wechseln</a>
    </li>
    <li (click)="onNavigationClick()" class="nav-item admin"><a class="nav-link px-3 py-2" [routerLink]="['/', _company, _edit]">Unternehmensprofil</a></li>
    <li (click)="onNavigationClick()" class="nav-item admin"><a class="nav-link px-3 py-2" [routerLink]="['/', _company, _media]">Bilder</a></li>
    <li (click)="onNavigationClick()" class="nav-item admin"><a class="nav-link px-3 py-2" [routerLink]="['/', _company, _contacts]">Kontakte / Anschriften</a></li>
    <li (click)="onNavigationClick()" class="nav-item admin"><a class="d-flex justify-content-between align-items-center nav-link px-3 py-2" (click)="logout()" [routerLink]="[]"><span class="text-black-50 fa fa-sign-out" aria-hidden="true"></span>Abmelden</a></li>
    <li (click)="onNavigationClick()" class="nav-item mt-5">
      <small><a class="nav-link px-2 py-2" [routerLink]="_contact">Kontakt</a></small>
    </li>
    <li (click)="onNavigationClick()" class="nav-item">
      <small><a class="nav-link px-2 py-2" [routerLink]="_privacyPolicy">Datenschutz</a></small>
    </li>
    <li (click)="onNavigationClick()" class="nav-item">
      <small><a class="nav-link px-2 py-2" [routerLink]="_imprint">Impressum</a></small>
    </li>
    <li class="px-2 py-2 border-0 text-muted mt-5">{{ user?.name }}</li>
    <li
      (click)="onNavigationClick()"
      *ngIf="user?.companyCustomerId && user?.companyCustomerId !== ''"
      class="nav-item px-2 py-2 border-0 text-muted"
    >
      Kundennummer: {{ user.companyCustomerId }}
    </li>
  </ul>
</div>
