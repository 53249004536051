import { EventEmitter, Inject, Injectable, InjectionToken, PLATFORM_ID } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { State, States } from '../data/types'
import { isPlatformBrowser } from '@angular/common'
import { Router } from "@angular/router";
import { _homepageBb, _homepageMv, _jobs } from '../../router/route-data'

@Injectable({
  providedIn: 'root'
})
export class StateSelectService {
  // TODO: enum for constants?
  private allowedRegionsMv = ['2', '3', '4', '5', '6', '7', '8', '9']
  private allowedRegionsBb = ['25', '26', '27', '28', '29', '30', '31', '32']
  isInitialized = false
  stateSubject: BehaviorSubject<States>
  state$: Observable<States>
  selectState: EventEmitter<boolean> = new EventEmitter()

  constructor(private router: Router, @Inject(PLATFORM_ID) private platformId: InjectionToken<Record<string, unknown>>) {
    this.stateSubject = new BehaviorSubject(States.mv)
    this.state$ = this.stateSubject.asObservable()
  }

  forceStateSelection(): void {
    this.selectState.emit(true)
  }
  private setState(newState: States, navigateIfNecessary = true): void {
    if (this.isInitialized && this.getState() === newState) {
      return
    }

    this.isInitialized = true
    this.stateSubject.next(newState)
    this.setLsValue(newState)

    if (!navigateIfNecessary) {
      return
    }

    if (
      (newState === States.mv && this.router.url === `/${_homepageBb}`) ||
      (newState === States.bb && this.router.url === `/${_homepageMv}`)
    ) {
      this.router.navigate([`/${newState === States.mv ? _homepageMv : _homepageBb}`])
    } else if (this.router.url.startsWith(`/${_jobs}`)) {
      this.router.navigate([`/${_jobs}`])
    }
  }

  setStateMv(navigateIfNecessary = true): void {
    this.setState(States.mv, navigateIfNecessary)
  }

  setStateBb(navigateIfNecessary = true): void {
    this.setState(States.bb, navigateIfNecessary)
  }

  setLsValue(state: State): void {
    if (!isPlatformBrowser(this.platformId)) {
      return
    }
    try {
      if (localStorage.getItem('jb_pp') === '1') {
        localStorage.setItem('current_state', state)
      }
    } catch (e) {
      // NOP
    }
  }

  getState(): string {
    return this.stateSubject.getValue()
  }

  getAllowedRegions(stateId?: string | boolean): string[] {
    if (!stateId) {
      stateId = this.stateSubject.getValue()
    }

    let allowedRegions = this.allowedRegionsMv
    if (stateId === States.bb) {
      allowedRegions = this.allowedRegionsBb
    }

    return allowedRegions
  }
}
