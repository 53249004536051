import { Component, Inject, InjectionToken, PLATFORM_ID, ViewChild } from "@angular/core";
import { StateSelectTemplateWrapperComponent } from './state-select-template-wrapper.component'
import { _homepageMv, _homepageBb } from 'src/app/router/route-data'
import { Router } from '@angular/router'
import { StateSelectService } from '../../../shared/services/state-select.service'
import { isPlatformBrowser } from '@angular/common'

@Component({
  selector: 'app-state-select',
  templateUrl: './state-select.component.html',
  styleUrls: ['./state-select.component.scss']
})
export class StateSelectComponent {
  _homepageMv = _homepageMv
  _homepageBb = _homepageBb
  @ViewChild('stateSelect') stateSelectTemplateWrapper: StateSelectTemplateWrapperComponent

  constructor(
    private router: Router,
    private stateSelectService: StateSelectService,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Record<string, unknown>>
  ) {  }

  navigateToMv() {
    this.stateSelectTemplateWrapper.hide()
    this.stateSelectService.setStateMv()
    this.scrollToTop()
    this.router.navigate([this._homepageMv])
  }

  navigateToBb(): void {
    this.stateSelectTemplateWrapper.hide()
    this.stateSelectService.setStateBb()
    this.scrollToTop()
    this.router.navigate([this._homepageBb])
  }

  scrollToTop(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return
    }

    window.scrollTo(0, 0)
  }
}
