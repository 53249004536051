<div class="row">
  <div class="col-md-12 page-header text-center d-flex align-items-center justify-content-center">
    <ng-particles
      aria-hidden="true"
      *ngIf="isBrowserWindow"
      id="tsparticles-about"
      [style]="myStyle"
      [particlesInit]="particlesInit"
      [options]="particlesOptions"
    ></ng-particles>
    <h1 class="d-none d-sm-block">Mehr erreichen mit dem Jobboten!</h1>
    <h1 class="d-block d-sm-none">Mehr erreichen!</h1>
  </div>
</div>

<div class="row dark">
  <div class="d-none d-sm-block col-12 col-md-10 col-lg-6 my-5 mx-auto text-left">
    <div class="row">
      <div class="col">
        <h2>Jobs aus deiner Region</h2>
        <p class="">
          Der Jobbote bringt dir deinen neuen Traumjob. Lege gleich los und finde noch heute freie Stellen in deiner Region. Alle
          veröffentlichten Stellenanzeigen sind von uns geprüft und aufbereitet. Wir stehen immer in direktem Kontakt mit den Arbeitgebern.
          ECHTE JOBS AUS DEINER REGION!
        </p>
      </div>
      <div class="col">
        <h2>Deine Daten gehören dir</h2>
        <p class="">
          Wir speichern keine persönlichen Daten von dir. Wir leiten dich immer direkt und ohne Umwege an die suchenden Unternehmen weiter.
          Auch wenn es manchmal bequemer wäre - DEINE DATEN GEHÖREN DIR!
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <a class="btn btn-outline-secondary" routerLink="/stellenanzeigen">Zu den Stellenanzeigen</a>
      </div>
    </div>
  </div>
  <div class="d-block d-sm-none col-12 col-md-10 col-lg-6 my-4 mx-auto text-center">
    <h2>Jobs aus deiner Region</h2>
    <p class="">
      Der Jobbote bringt dir deinen neuen Traumjob. Lege gleich los und finde noch heute freie Stellen in deiner Region. Alle
      veröffentlichten Stellenanzeigen sind von uns geprüft und aufbereitet. Wir stehen immer in direktem Kontakt mit den Arbeitgebern.
      ECHTE JOBS AUS DEINER REGION!
    </p>
    <h2 class="mt-3 mt-sm-0">Deine Daten gehören dir</h2>
    <p class="">
      Wir speichern keine persönlichen Daten von dir. Wir leiten dich immer direkt und ohne Umwege an die suchenden Unternehmen weiter. Auch
      wenn es manchmal bequemer wäre - DEINE DATEN GEHÖREN DIR!
    </p>
    <a class="btn btn-outline-secondary" routerLink="/bundesland">Zu den Stellenanzeigen</a>
  </div>
</div>

<div class="row">
  <div class="col-12 col-md-10 col-lg-6 d-none d-sm-flex justify-content-between align-items-center mt-4 mb-5 mx-auto text-left">
    <img alt="Portrait Peter Schmidt" class="portrait mr-4 mb-3" src="/assets/img/foto.png" />
    <div>
      <h2>Stellen einfach besetzen</h2>
      <p>
        Nutzen Sie die regionale Kraft und Reichweite eines Radio-Spots gepaart mit der Präsenz Ihrer Online-Stellenanzeigen. Hören und
        finden - mobiloptimiert für alle Endgeräte und Smartphones, natürlich auch Google-for-Jobs ready. Professionelle Radiospots und
        Multi-User-Zugriff für die Verwaltung Ihrer Online-Stellenanzeigen. FULL-SERVICE alles aus einer Hand.
      </p>
      <a class="mt-2 mb-4 btn btn-outline-secondary" [routerLink]="['../', _companyInfo]">Jetzt informieren</a>
    </div>
  </div>
  <div
    class="col-12 col-md-10 col-lg-6 d-flex d-sm-none flex-column justify-content-between align-items-center mt-4 mb-5 mx-auto text-left"
  >
    <img alt="Portrait Peter Schmidt" class="portrait mb-3" src="/assets/img/foto.png" />
    <div class="text-center">
      <h2>Stellen einfach besetzen</h2>
      <p class="text-justify">
        Nutzen Sie die regionale Kraft und Reichweite eines Radio-Spots gepaart mit der Präsenz Ihrer Online-Stellenanzeigen. Hören und
        finden - mobiloptimiert für alle Endgeräte und Smartphones, natürlich auch Google-for-Jobs ready. Professionelle Radiospots und
        Multi-User-Zugriff für die Verwaltung Ihrer Online-Stellenanzeigen. FULL-SERVICE alles aus einer Hand.
      </p>
      <a class="mt-2 mb-4 btn btn-outline-secondary" [routerLink]="['../', _companyInfo]">Jetzt informieren</a>
    </div>
  </div>
</div>
