import { Inject, Injectable, InjectionToken, PLATFORM_ID } from "@angular/core";
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router'
import { filter, map, mergeMap, take } from "rxjs/operators";
import { BehaviorSubject, Observable } from "rxjs";
import { HeroState, HeaderState, AppRouteData } from './route-data'
import { Gtag } from 'angular-gtag'
import { SeoService } from '../shared/services/seo.service'
import { isPlatformBrowser } from '@angular/common'
import { StateSelectService } from "../shared/services/state-select.service";
import { JobService } from "../shared/services/job.service";
import { States } from "../shared/data/types";
import { MatomoTracker } from "@ngx-matomo/tracker";

@Injectable({ providedIn: 'root' })
export class RouterService {
  pageviewCounted: boolean
  heroStateSubject: BehaviorSubject<HeroState>
  $heroState: Observable<HeroState>

  headerStateSubject: BehaviorSubject<HeaderState>
  $headerState: Observable<HeaderState>

  lastActivatedRouteData: AppRouteData

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private readonly tracker: MatomoTracker,
    private seoService: SeoService,
    private jobService: JobService,
    private gtag: Gtag,
    private stateSelectService: StateSelectService,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Record<string, unknown>>
  ) {
    this.tracker.enableHeartBeatTimer(5)

    this.pageviewCounted = false
    this.router.initialNavigation()

    // HeroState
    this.heroStateSubject = new BehaviorSubject(HeroState.none)
    this.$heroState = this.heroStateSubject.asObservable()

    // HeaderState
    this.headerStateSubject = new BehaviorSubject(HeaderState.none)
    this.$headerState = this.headerStateSubject.asObservable()

    const routeData: Observable<AppRouteData> = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((_activatedRoute) => {
        while (_activatedRoute.firstChild) _activatedRoute = _activatedRoute.firstChild
        return _activatedRoute
      }),
      mergeMap((route) => {
        return route.data as Observable<AppRouteData>
      })
    )

    routeData.subscribe((currentRouteData: AppRouteData) => {
      this.heroStateSubject.next(currentRouteData.heroState)
      this.headerStateSubject.next(currentRouteData.headerState)

      this.lastActivatedRouteData = currentRouteData
      this.seoService.updateData(currentRouteData.seoData)

      if (currentRouteData.regionLabel) {
        this.jobService.$regions.pipe(take(1)).subscribe(() => {
          this.jobService.setQueryRegionByLabel(currentRouteData.regionLabel)
        })

        if (currentRouteData.state === States.mv) {
          this.stateSelectService.setStateMv()
        } else {
          this.stateSelectService.setStateBb()
        }
      } else if (!currentRouteData.requiresNoStateSelection && !this.stateSelectService.isInitialized) {
        this.stateSelectService.forceStateSelection()
      }

      this.trackPageview().then(() => {})
    })
  }

  public async trackPageview() {
    if (!isPlatformBrowser(this.platformId) || !this.router || !this.router.routerState || !this.router.routerState.snapshot) return

    // simple hit counter for the website. We only want to count each user session once!
    if (!this.pageviewCounted) {
      this.pageviewCounted = true
      // TODO: fix this --> do it asynchronous after 5 seconds?
      //this.wordpressService.incrementNumberOfPageViews().subscribe((res) => {});
    }

   // this.matomoTracker.enableHeartBeatTimer(2)
    const slugBlacklist = [
        '/unternehmen',
        '/unternehmen/bearbeiten',
        '/unternehmen/kontakte',
        '/unternehmen/registrieren',
        '/unternehmen/passwort-vergessen'
      ],
      currentPath = this.router.routerState.snapshot.url

    let trackingIsAllowed = false
    try {
      const getCookieValue = localStorage.getItem('jb_pp_ga')
      trackingIsAllowed = (getCookieValue === '1')
    } catch (e) {
      // NOP
    }

    // these routes should not be logged in GA
    if (typeof currentPath === 'string') {
      const currentPathParts = currentPath.split('?')
      if (currentPathParts?.length && slugBlacklist.indexOf(currentPathParts[0]) > -1) return

      // available: page_title, page_location, page_path
      const googleParams = {
        page_path: currentPath
      }

      if (this.lastActivatedRouteData?.seoData?.title) {
        const seoTitle = this.lastActivatedRouteData.seoData.title;
        googleParams['page_title'] = this.lastActivatedRouteData.seoData.title
        this.tracker.trackPageView(seoTitle)
      } else {
        this.tracker.trackPageView()
      }

      if (trackingIsAllowed) {
        this.gtag.pageview(googleParams)
      }
    }
  }
}
