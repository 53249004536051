<div class="row my-4">
  <div class="col-sm-1 col-lg-3"></div>
  <div class="col-xs-12 col-sm-10 col-lg-6 text-center">
    <h1 class="mb-4">Ihr Kontakt zum Jobboten</h1>
    <p>
      Sie haben Fragen zu unseren Produkten oder möchten eine Stellenanzeige aufgeben? Wir freuen uns über Ihren Anruf oder Ihre Nachricht
      an <a href="mailto:anzeigenservice@derjobbote.de">anzeigenservice@derjobbote.de</a>!
    </p>
    <div class="row mt-4">
      <div class="col">
        Region Mecklenburg-Vorpommern<br />Grubenstraße 20 | 18055 Rostock<br /><a href="tel:+4938145360010">0381 / 453 600 10</a>
      </div>
      <div class="col">
        Region Berlin- Brandenburg<br />Behlertstraße 3a | 14467 Potsdam<br /><a href="tel:+493312352163">0331 / 235 21 63</a>
      </div>
    </div>
    <form *ngIf="!submitSuccessful" [formGroup]="contactForm" class="my-4" id="contact-form" name="contact-form">
      <div class="row text-left">
        <div class="col-md-6">
          <div class="md-form mb-1">
            <input
              [ngClass]="{ invalid: (!name.valid && name.touched) || (!name.valid && evaluated) }"
              type="text"
              placeholder=""
              id="name"
              name="name"
              class="form-control"
              formControlName="name"
            />
            <label for="name" class="">Name *</label>
          </div>
        </div>
        <div class="col-md-6">
          <div class="md-form mb-1">
            <input
              [ngClass]="{ invalid: (!email.valid && email.touched) || (!email.valid && evaluated) }"
              type="email"
              placeholder=""
              id="email"
              name="email"
              class="form-control"
              formControlName="email"
            />
            <label for="email" class="">E-Mail *</label>
          </div>
        </div>
      </div>

      <div class="row text-left">
        <div class="col-md-6">
          <div class="md-form mb-1">
            <input type="text" id="company" placeholder="" name="company" class="form-control" formControlName="company" />
            <label for="company" class="">Unternehmen</label>
          </div>
        </div>
        <div class="col-md-6">
          <div class="md-form mb-0">
            <input type="tel" placeholder="" id="telephone" name="telephone" class="form-control" formControlName="telephone" />
            <label for="telephone" class="">Telefon</label>
          </div>
        </div>
      </div>

      <div class="row text-left">
        <div class="col-md-12">
          <div class="md-form mb-0">
            <input type="text" placeholder="" id="subject" name="subject" class="form-control" formControlName="subject" />
            <label for="subject" class="">Betreff</label>
          </div>
        </div>
      </div>
      <div class="row text-left">
        <div class="col-md-12">
          <div class="md-form">
            <textarea
              [ngClass]="{ invalid: (!message.valid && message.touched) || (!message.valid && evaluated) }"
              required
              type="text"
              id="message"
              data-test="message"
              name="message"
              rows="5"
              class="form-control md-textarea"
              formControlName="message"
            ></textarea>
            <label for="message">Nachricht *</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 text-left">
          <div
            class="form-check mt-1 mb-3 py-1 rounded"
            [ngClass]="{
              invalid: (!privacyPolicyAccepted.valid && privacyPolicyAccepted.touched) || (!privacyPolicyAccepted.valid && evaluated)
            }"
          >
            <input formControlName="privacyPolicyAccepted" type="checkbox" class="form-check-input" id="privacyPolicyAccepted" />
            <label class="form-check-label" for="privacyPolicyAccepted"
              >Ich stimme der <a target="_blank" [routerLink]="['/', _privacyPolicy]">Datenschutzerklärung</a> zu.</label
            >
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <input
            [ngClass]="{ invalid: (!captcha.valid && captcha.touched) || (!captcha.valid && evaluated) }"
            type="text"
            placeholder="1 + 1 = "
            id="captcha"
            name="captcha"
            class="form-control"
            formControlName="captcha"
          />
        </div>
        <div class="col-12 col-md-3">
          <button (click)="onSubmit()" [disabled]="isSubmitting" class="btn btn-secondary btn-block">Senden</button>
        </div>
      </div>
    </form>
    <div *ngIf="submitSuccessful" class="mt-4">
      <alert type="success">Ihre Nachricht wurde erfolgreich an uns gesendet. Wir setzen uns umgehend mit Ihnen in Verbindung.</alert>
    </div>
  </div>
</div>
