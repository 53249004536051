import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  StateSelectCompanyTemplateWrapperComponent
} from '../../pages/public/company-home-page/state-select-company-template-wrapper.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { RouterModule } from '@angular/router';
import { LoadingComponent } from "../../shared/components/loading/loading.component";
import { LottieComponent } from "ngx-lottie";

export const DEFAULT_TITLE = 'Der Jobbote - Regionale Stellenangebote in Mecklenburg-Vorpommern und Berlin-Brandenburg'
export const REGION_TITLE = 'Der Jobbote - Regionale Stellenangebote in '

@NgModule({
  declarations: [
    StateSelectCompanyTemplateWrapperComponent,
    LoadingComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    LottieComponent,
    ModalModule.forRoot(),
  ],
  exports: [
    StateSelectCompanyTemplateWrapperComponent,
    LoadingComponent,
  ]
})
export class SharedModule { }
