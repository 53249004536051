<nav class="navbar d-none d-lg-flex navbar-expand-lg footer navbar-light">
  <img width="150" height="50" alt="Logo Der Jobbote" [priority]="true" ngSrc="../../../assets/img/logo_jobbote.svg" />

  <div class="mx-auto footerNavigation">
    <ul class="nav no-style navbar-nav mx-md-auto mt-4 mt-lg-0 d-flex justify-content-center">
      <li class="nav-item py-1" (click)="scrollToTop()">
        <a *ngIf="!isStateMv" class="nav-link" [routerLink]="['/', _homepageBb]">Startseite</a>
        <a *ngIf="isStateMv" class="nav-link" [routerLink]="['/', _homepageMv]">Startseite</a>
      </li>
      <li (click)="scrollToTop()" class="nav-item py-1"><a class="nav-link employer" [routerLink]="_companyInfo">Für Arbeitgeber</a></li>
      <li (click)="scrollToTop()" class="nav-item py-1"><a class="nav-link" [routerLink]="_aboutUs">Über uns</a></li>
      <li (click)="scrollToTop()" class="nav-item py-1"><a class="nav-link" [routerLink]="_contact">Kontakt</a></li>
      <li (click)="scrollToTop()" class="nav-item py-1"><a class="nav-link" [routerLink]="_conditions">AGB</a></li>
      <li (click)="scrollToTop()" class="nav-item py-1"><a class="nav-link" [routerLink]="_privacyPolicy">Datenschutz</a></li>
      <li (click)="scrollToTop()" class="nav-item py-1"><a class="nav-link" [routerLink]="_imprint">Impressum</a></li>
    </ul>
  </div>
  <a class="nav-link" id="fb-link" title="Besuchen Sie uns auf Facebook" href="https://www.facebook.com/derjobbote/">
    <em class="d-block fa fa-facebook-official fa-lg"></em>
  </a>
</nav>
