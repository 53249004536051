import { Component, Inject, InjectionToken, OnInit, PLATFORM_ID } from '@angular/core'
import { _companyInfo } from 'src/app/router/route-data'
import { isPlatformBrowser } from '@angular/common'
import { loadSlim } from 'tsparticles-slim';
import { Engine } from 'tsparticles-engine';
import { IParticlesProps } from 'ng-particles/lib/ng-particles.module';

@Component({
  selector: 'app-about-us-page',
  templateUrl: './about-us-page.component.html',
  styleUrls: ['./about-us-page.component.scss']
})
export class AboutUsPageComponent implements OnInit {
  _companyInfo = _companyInfo

  myStyle: Record<string, string | number> = {}
  particlesOptions: IParticlesProps = {}
  isBrowserWindow = false

  constructor(@Inject(PLATFORM_ID) private platformId: InjectionToken<Record<string, unknown>>) {
    this.isBrowserWindow = isPlatformBrowser(this.platformId)
  }

  ngOnInit(): void {
    this.myStyle = {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    }
    this.particlesOptions = {
      autoPlay: true,
      detectRetina: true,
      duration: 0,
      fpsLimit: 60,
      interactivity: {
        detectsOn: 'parent',
        events: {
          onHover: {
            enable: true,
            mode: 'grab'
          },
          resize: true
        },
        modes: {
          connect: {
            distance: 250,
            links: {
              opacity: 0.5
            },
            radius: 100
          },
          grab: {
            distance: 200,
            links: {
              opacity: 1
            }
          }
        }
      },
      particles: {
        color: {
          value: '#394a77'
        },
        links: {
          blink: false,
          color: {
            value: '#394a77'
          },
          consent: false,
          distance: 150,
          enable: true,
          frequency: 1,
          opacity: 0.25,
          width: 2,
          warp: false
        },
        move: {
          angle: {
            offset: 0,
            value: 90
          },
          enable: true,
          speed: 1
        },
        number: {
          density: {
            enable: true,
            area: 800,
            factor: 1000
          },
          limit: 0,
          value: 100
        },
        opacity: {
          random: {
            enable: true,
            minimumValue: 0.3
          },
          value: {
            min: 0.3,
            max: 0.8
          },
          animation: {
            count: 0,
            enable: true,
            speed: 0.5,
            sync: false,
            destroy: 'none',
            minimumValue: 0.3,
            startValue: 'random'
          }
        },
        reduceDuplicates: false,
        shape: {
          options: {},
          type: 'circle'
        }
      },
      pauseOnBlur: true,
      pauseOnOutsideViewport: true,
      responsive: [],
      fullScreen: false
    }
  }

  async particlesInit(engine: Engine): Promise<void> {
    await loadSlim(engine)
  }
}
