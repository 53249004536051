import { Component, OnInit, OnDestroy, ViewChild, HostListener } from '@angular/core'
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { _jobs } from 'src/app/router/route-data'
import { JobService, RegionQuery } from 'src/app/shared/services/job.service'
import { Taxonomy } from 'src/app/shared/data/types'
import { Subscription } from "rxjs";
import { LayoutService } from 'src/app/shared/services/layout.service'
import { Router } from "@angular/router";

const FALLBACK_BAR_HEIGHT = 70

interface SearchForm {
    searchString: FormControl<string>;
    regionId: FormControl<string>;
}

@Component({
  selector: 'app-job-search-small',
  templateUrl: './job-search-small.component.html',
  styleUrls: ['./job-search-small.component.scss']
})
export class JobSearchSmallComponent implements OnInit, OnDestroy {
  @ViewChild('headContainer', { static: true }) headContainer
  @ViewChild('headContainerMobile', { static: true }) headContainerMobile

  // Data
  regions: Taxonomy[] = []
  regionQuery: RegionQuery
  regionQuerySub: Subscription
  regionsSub: Subscription

  // Avoids infinite change loop on regionQuery observable.
  updateRegionQueryLocal = false

  // Routes
  _jobs = `${_jobs}`

  // Form
  searchForm: FormGroup<SearchForm> = this.formBuilder.group({ searchString: [''], regionId: [''] })

  get regionId(): AbstractControl<string, string> {
    return this.searchForm.get('regionId')
  }
  get searchString(): AbstractControl<string, string> {
    return this.searchForm.get('searchString')
  }

  @HostListener('window:resize')
  onResize(): void {
    this.emitHeight()
  }

  constructor(
    private jobService: JobService,
    private formBuilder: FormBuilder,
    private layoutService: LayoutService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.emitHeight()

    this.searchString.valueChanges.subscribe((value) => {
      this.regionQuery.searchString = value
      this.jobService.updateRegionQuery(this.regionQuery)
    })

    this.regionsSub = this.jobService.$regions.subscribe((regions) => {
      if (!regions) {
        return
      }

      this.regions = regions
    })

    // Subscribe to the regionQuery, adapt forms on change.
    this.regionQuerySub = this.jobService.$regionQuery.subscribe((regionQuery) => {
      if (!regionQuery) return
      this.regionQuery = regionQuery
      if (!this.updateRegionQueryLocal) {
        if (regionQuery.searchString && this.searchString.value !== regionQuery.searchString) {
          this.searchString.setValue(regionQuery.searchString, {onlySelf: true})
        }
        if (regionQuery.region && this.regionId.value !== regionQuery.region.id) {
          this.regionId.setValue(regionQuery.region.id, {onlySelf: true})
        }
      }
    })
  }

  updateRegion(id: string): void {
    if (!this.regions) {
      console.error('no regions available for selection')
    }

    this.updateRegionQueryLocal = true
    this.regionQuery.region = this.regions.filter((region) => region.id == id)[0]
    this.jobService.updateRegionQuery(this.regionQuery)
    this.updateRegionQueryLocal = false
  }

  emitHeight(): void {
    let height = FALLBACK_BAR_HEIGHT

    if (this.headContainer && this.headContainerMobile) {
      const desktopHeight = this.headContainer.nativeElement.offsetHeight
      const mobileHeight = this.headContainerMobile.nativeElement.offsetHeight

      height = desktopHeight > mobileHeight ? desktopHeight : mobileHeight
      if (!height || height === 0) {
        height = FALLBACK_BAR_HEIGHT
      }
    }

    this.layoutService.heroHeightSubject.next(height + 'px')
  }

  ngOnDestroy(): void {
    if (this.regionQuerySub) this.regionQuerySub.unsubscribe()
    if (this.regionsSub) this.regionsSub.unsubscribe()
    this.layoutService.heroHeightSubject.next('0px')
  }

  search(): void {
    this.jobService.regionQuery()
    this.router.navigate([this._jobs])
  }
}
