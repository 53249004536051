import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { AppComponent } from './app.component'
import { HeaderComponent } from './layout/header/header.component'
import { FooterComponent } from './layout/footer/footer.component'
import { StateSelectComponent } from './pages/public/state-select/state-select.component'
import { ProgressBarComponent } from './shared/components/progress-bar/progress-bar.component'
import { CookieBarComponent } from './layout/cookie-bar/cookie-bar.component'
import { JwtInterceptorService } from './shared/services/user/jwt-interceptor.service'

// https://valor-software.com/ngx-bootstrap/#/
import { NgParticlesModule } from 'ng-particles'

import { ToastrModule } from 'ngx-toastr'
import { NotFoundPageComponent } from './pages/public/not-found-page/not-found-page.component'
import { AppRouterModule } from './router/app-router.module'
import { HeroComponent } from './layout/hero/hero.component'
import { ContactPageComponent } from './pages/public/contact-page/contact-page.component'
import { AboutUsPageComponent } from './pages/public/about-us-page/about-us-page.component'
import { CustomPageComponent } from './shared/components/custom-page/custom-page.component'
import { StateSelectTemplateWrapperComponent } from './pages/public/state-select/state-select-template-wrapper.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { SecondaryNavigationComponent } from './shared/components/secondary-navigation/secondary-navigation.component'
import { JobSearchSmallComponent } from './layout/hero/job-search-small/job-search-small.component'
import { CompanyNavigationComponent } from './shared/components/company-navigation/company-navigation.component'
import { ErrorComponent } from './pages/public/error.component'
import { NewsletterPageComponent } from './pages/public/newsletter-page/newsletter-page.component'
import {APP_BASE_HREF, NgOptimizedImage} from '@angular/common'
import {LottieModule} from "ngx-lottie";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { GtagModule } from "angular-gtag";
import { ModalModule } from "ngx-bootstrap/modal";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { SharedModule } from "./modules/shared/shared.module";
import { NgxMatomoTrackerModule } from "@ngx-matomo/tracker";
import { environment } from "../environments/environment";

export function playerFactory() {
  return import('lottie-web/build/player/lottie_light');
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    StateSelectComponent,
    ProgressBarComponent,
    CookieBarComponent,
    NotFoundPageComponent,
    HeroComponent,
    ContactPageComponent,
    AboutUsPageComponent,
    CustomPageComponent,
    StateSelectTemplateWrapperComponent,
    SecondaryNavigationComponent,
    JobSearchSmallComponent,
    CompanyNavigationComponent,
    ErrorComponent,
    NewsletterPageComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRouterModule,
    BsDatepickerModule.forRoot(),
    GtagModule.forRoot({ trackingId: 'G-2YVYFRQ413', trackPageviews: false }),
    NgxMatomoTrackerModule.forRoot({
      siteId: '1',
      trackerUrl: `${environment.API_URL}wp-content/plugins/matomo/app/`,
      scriptUrl: `${environment.API_URL}wp-content/uploads/matomo/matomo.js`,
      trackAppInitialLoad: true,
    }),
    LottieModule.forRoot({ player: playerFactory }),
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      iconClasses: {
        error: 'toast-error',
        info: 'toast-info',
        success: '',
        warning: 'toast-warning'
      }
    }),
    HttpClientModule, // required by WordpressService
    FormsModule, // required by job-search-small.component
    ReactiveFormsModule, // required by job-search-small.component
    NgParticlesModule, // required by about-us.component
    NgOptimizedImage, // required by home components
    BrowserAnimationsModule,
    ModalModule.forRoot(),
    SharedModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptorService,
      multi: true
    },
    { provide: APP_BASE_HREF, useValue: '/' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
