import { Injectable } from '@angular/core'
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http'
import { Observable } from 'rxjs'
import { switchMap, take } from 'rxjs/operators'
import { UserService } from './user.service'

@Injectable({ providedIn: 'root' })
export class JwtInterceptorService implements HttpInterceptor {
  constructor(private userService: UserService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.params.has('superuser')) {
      return next.handle(request)
    }

    if (request.params.has('superuserpost')) {
      const token = request.params.get('superuserpost')
      const params = request.params.delete('superuserpost')
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        },
        params: params
      })
      return next.handle(request)
    }

    return this.userService.$user.pipe(
      take(1),
      switchMap((user) => {
        if (user?.token) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${user.token}`
            }
          })
        }
        return next.handle(request)
      })
    )
  }
}
