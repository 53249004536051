import { Component, Inject, InjectionToken, OnInit, Optional, PLATFORM_ID } from '@angular/core'
import { isPlatformServer } from '@angular/common'
import { RESPONSE } from '@nguniversal/express-engine/tokens'
import { Response } from 'express'

@Component({
  selector: 'app-error',
  template: `
    <div class="row mt-5">
      <div class="col-12 col-sm-8 col-lg-6 col-xl-4 mx-auto text-center">
        <h2 class="mt-2">Der Jobbote ist gleich wieder da</h2>
        <i class="fa fa-wrench my-4" style="font-size: 4rem;"></i>
        <p class="lead">
          Wir sind gerade dabei einige Verbesserungen am Jobboten vorzunehmen. Bitte versuchen Sie es in 30 Minuten erneut.
        </p>
      </div>
    </div>
  `,
  styles: [``]
})
export class ErrorComponent implements OnInit {
  constructor(@Inject(PLATFORM_ID) private platformId: InjectionToken<Record<string, unknown>>, @Optional() @Inject(RESPONSE) private response: Response) {}

  ngOnInit() {
    if (isPlatformServer(this.platformId)) {
      this.response.status(503)
    }
  }
}
