import { Component, Inject, InjectionToken, OnDestroy, OnInit, PLATFORM_ID } from "@angular/core";
import { ActivatedRoute } from '@angular/router'
import { take } from 'rxjs/operators'
import { WordpressService } from '../../services/wordpress.service'
import { CustomPage, SpecialOffer, BookingType } from '../../data/types'
import { BookingService } from '../../services/booking.service'
import { StateSelectService } from '../../services/state-select.service'
import { isPlatformBrowser } from '@angular/common'
import { Subscription } from 'rxjs'
import { SeoService } from '../../services/seo.service'
import { ToastrService } from 'ngx-toastr'
import { CookieConsentService } from '../../services/cookie-consent.service'

@Component({
  selector: 'app-custom-page',
  templateUrl: './custom-page.component.html',
  styleUrls: ['./custom-page.component.scss']
})
export class CustomPageComponent implements OnInit, OnDestroy {
  customPage: CustomPage
  specialOffer: SpecialOffer
  stateId: string
  activatedRouteSub: Subscription

  constructor(
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Record<string, unknown>>,
    private activatedRoute: ActivatedRoute,
    private seoService: SeoService,
    private wordpressService: WordpressService,
    private bookingService: BookingService,
    private toastr: ToastrService,
    private stateSelectService: StateSelectService,
    private cookieConsentService: CookieConsentService
  ) {
    this.activatedRoute.params.subscribe((params) => {
      this.stateId = params['var']
    })
  }

  ngOnInit() {
    // Resolve urlSegment from route.
    this.activatedRoute.url.pipe(take(1)).subscribe((urlSegments) => {
      const urlSegment = urlSegments[0].path
      // Get custompage by urlSegment
      this.wordpressService.getCustomPageByUrlSegment(urlSegment).subscribe((customPage) => {
        this.customPage = customPage

        this.seoService.updateData({
          title: customPage.title,
          path: customPage.urlSegment
        })
      })
    })
  }

  checkCookiesAllowed(): boolean {
    try {
      const getCookieValue = localStorage.getItem('jb_pp')
      if (getCookieValue !== '1') {
        const text =
          'Cookies sind technisch erforderlich um die Buchung durchzuführen.\n\n' +
          'Klicken Sie "OK", um den Einsatz zuzustimmen.\nSie können die Zustimmung jederzeit wiederrufen.\nWeitere Informationen unter https://derjobbote.de/datenschutz'
        if (confirm(text) === true) {
          this.cookieConsentService.setCookiesAllowed()
        } else {
          this.cookieConsentService.setCookiesDisallowed()
          return false
        }
      }
    } catch (e) {
      console.error(e)
      this.toastr.error(
        'Ihr Browser verhindert den Zugriff auf Cookies. Bitte fügen Sie eine Ausnahme für diese Seite hinzu oder erlauben Sie Cookies von Dritten',
        '',
        {
          timeOut: 7500
        }
      )
      return false
    }

    return true
  }

  navigate(specialOffer: SpecialOffer): void {
    let allowNavigation = true
    if (isPlatformBrowser(this.platformId)) {
      allowNavigation = this.checkCookiesAllowed()
    }

    if (allowNavigation) {
      if (this.stateId === 'bb') {
        this.stateSelectService.setStateBb()
      } else {
        this.stateSelectService.setStateMv()
      }
      this.bookingService.navigate(BookingType.specialoffer, specialOffer)
    }
  }

  ngOnDestroy() {
    if (this.activatedRouteSub) {
      this.activatedRouteSub.unsubscribe()
    }
  }
}
