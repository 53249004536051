import { Component, Inject, InjectionToken, OnDestroy, OnInit, PLATFORM_ID } from "@angular/core";
import { UntypedFormBuilder, Validators } from '@angular/forms'
import { _privacyPolicy, _companyInfo } from 'src/app/router/route-data'
import { WordpressService } from '../../../shared/services/wordpress.service'
import { Subscription } from 'rxjs'
import { ActivatedRoute } from '@angular/router'
import { isPlatformBrowser } from '@angular/common'

@Component({
  selector: 'app-newsletter-page',
  templateUrl: './newsletter-page.component.html',
  styleUrls: ['./newsletter-page.component.scss']
})
export class NewsletterPageComponent implements OnInit, OnDestroy {
  // Routes
  _privacyPolicy = `/${_privacyPolicy}`
  _companyInfo = _companyInfo

  isRegistration: boolean
  activatedRouteSub: Subscription
  pageHitSubscription: Subscription
  evaluated = false
  newsletterForm = this.formBuilder.group({
    email: ['', Validators.email],
    privacyPolicyAccepted: ['', Validators.required]
  })
  get email() {
    return this.newsletterForm.get('email')
  }
  get privacyPolicyAccepted() {
    return this.newsletterForm.get('privacyPolicyAccepted')
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Record<string, unknown>>,
    private wordpressService: WordpressService
  ) {
    this.isRegistration = true
  }

  ngOnInit() {
    if (!isPlatformBrowser(this.platformId)) {
      return
    }

    this.activatedRouteSub = this.activatedRoute.params.subscribe((params) => {
      this.isRegistration = !params || !params.user
    })
  }

  onSubmit() {
    if (!this.newsletterForm.valid) {
      this.evaluated = true
      return
    }

    this.wordpressService
      .postNewsletter({
        email: this.email.value
      })
      .subscribe(() => {})
  }

  ngOnDestroy(): void {
    if (this.activatedRouteSub) {
      this.activatedRouteSub.unsubscribe()
    }
  }
}
