<div class="row mt-4 mb-2">
  <div data-test="custom-page-content" class="col-xs-10 col-sm-10 col-md-8 col-lg-6 mx-auto" [innerHtml]="customPage?.content"></div>
</div>
<div *ngIf="specialOffer">
  <div class="row mb-5">
    <div class="col-xs-10 col-sm-10 col-md-8 col-lg-6 mx-auto">
      <button *ngIf="specialOffer" class="btn btn-secondary pull-right" (click)="navigate(specialOffer)">Jetzt buchen</button>
    </div>
  </div>
</div>
