import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, EMPTY, from, Observable, of, ReplaySubject, skip, Subject, Subscription } from "rxjs";
import { Job, JobListElement, State, States, Taxonomy } from '../data/types'
import { WordpressService } from './wordpress.service'
import { filter, switchMap, take } from "rxjs/operators";
import { StateSelectService } from './state-select.service'
import { MatomoTracker } from "@ngx-matomo/tracker";

export interface RegionQuery {
  startCursor: number
  pageSize: number
  searchString: string
  region: Taxonomy
}

export interface SearchQuery {
  searchString: string
  locationString: string
  distanceInKm: string
}

const DEFAULT_REGION = '__DEFAULT__'

@Injectable({ providedIn: 'root' })
export class JobService implements OnDestroy {
  private regionsSubject = new ReplaySubject<Taxonomy[]>(1)

  public isFirstSearch: boolean = true
  private lastActiveJobId: string = ''
  private lastActiveJob: Job
  private activeJobSubject = new Subject<Job>()
  private readonly stateSub: Subscription

  $activeJob = this.activeJobSubject.asObservable()
  $regions: Observable<undefined | Taxonomy[]> = this.regionsSubject.asObservable()

  // Shared
  /////////

  // This is consumed by the lists. It can be set by any query or local operations.
  private jobsSubject = new BehaviorSubject<JobListElement[]>(undefined)
  $jobs = this.jobsSubject.asObservable()

  // Region Query
  ///////////////

  private lastRegionQuery: RegionQuery = null
  private regionQuerySubject = new BehaviorSubject<RegionQuery>({
    startCursor: 0,
    pageSize: 15,
    searchString: '',
    region: undefined
  })
  $regionQuery = this.regionQuerySubject.asObservable()

  private defautRegionUpdateSubject: Subject<boolean> = new ReplaySubject(1)
  public $defautRegionUpdate = this.defautRegionUpdateSubject.asObservable()

  private regionQueryResultsSubject: Subject<JobListElement[]> = new Subject<JobListElement[]>()
  $regionQueryResults = this.regionQueryResultsSubject.asObservable()

  constructor(
    private wordpressService: WordpressService,
    private stateSelectService: StateSelectService,
    private readonly tracker: MatomoTracker
  ) {
    this.stateSub = this.stateSelectService.state$.pipe(skip(1)).subscribe((value) => {
      this.regionsSubject.next(this.updateRegionsLocal(value))
    })
  }

  public resetActiveRegion(): void {
    this.setQueryRegionByLabel(DEFAULT_REGION)
  }

  public updateRegionsLocal(value: State): Taxonomy[] {
    if (value === States.bb) {
      return [
        {
          id: '0',
          label: 'Alle Regionen',
          taxonomy: ''
        },
        {
          id: '25',
          label: 'Berlin',
          taxonomy: 'region'
        },
        {
          id: '26',
          label: 'Brandenburg / Havelland',
          taxonomy: 'region'
        },
        {
          id: '27',
          label: 'Elbe-Elster / Oberspreewald-Lausitz / Spree-Neiße',
          taxonomy: 'region'
        },
        {
          id: '28',
          label: 'Frankfurt / Oder-Spree',
          taxonomy: 'region'
        },
        {
          id: '32',
          label: 'Potsdam / Potsdam-Mittelmark',
          taxonomy: 'region'
        },
        {
          id: '29',
          label: 'Prignitz / Ostprignitz-Ruppin / Oberhavel',
          taxonomy: 'region'
        },
        {
          id: '30',
          label: 'Teltow-Fläming / Dahme-Spreewald',
          taxonomy: 'region'
        },
        {
          id: '31',
          label: 'Uckermark / Barnim / Märkisch Oderland',
          taxonomy: 'region'
        }
      ]
    } else {
      return [
        {
          id: '-1',
          label: 'Alle Regionen',
          taxonomy: ''
        },
        {
          id: '2',
          label: 'Landkreis Rostock',
          taxonomy: 'region'
        },
        {
          id: '3',
          label: 'Ludwigslust-Parchim',
          taxonomy: 'region'
        },
        {
          id: '4',
          label: 'Mecklenburgische Seenplatte',
          taxonomy: 'region'
        },
        {
          id: '5',
          label: 'Nordwestmecklenburg',
          taxonomy: 'region'
        },
        {
          id: '7',
          label: 'Schwerin',
          taxonomy: 'region'
        },
        {
          id: '6',
          label: 'Stadt Rostock',
          taxonomy: 'region'
        },
        {
          id: '8',
          label: 'Vorpommern-Greifswald',
          taxonomy: 'region'
        },
        {
          id: '9',
          label: 'Vorpommern-Rügen',
          taxonomy: 'region'
        }
      ]
    }
  }

  public initialize(_preselectedRegionLabel: string = null, _skipNextUpdate = false): void {
    // subscribe to state changes but skip initial value
  }

  ngOnDestroy(): void {
    if (this.stateSub) {
      this.stateSub.unsubscribe()
    }
  }

  // Active List Job
  //////////////////

  setActiveJob(id: string): void {
    if (this.lastActiveJobId === id) {
      this.activeJobSubject.next(this.lastActiveJob)
      return
    }
    this.lastActiveJobId = id
    this.wordpressService
      .getJobById(id)
      .pipe(take(1))
      .subscribe((job) => {
        this.lastActiveJob = job
        this.activeJobSubject.next(job)
      })
  }

  // Performs the backend regionQuery by current regionQuery params.
  regionQuery(appendResult = false): void {
    this.$regionQuery.pipe(take(1)).subscribe((regionQuery: RegionQuery) => {
      if (!regionQuery?.region) return
      if (
        this.lastRegionQuery &&
        this.lastRegionQuery.region === regionQuery.region &&
        this.lastRegionQuery.startCursor === regionQuery.startCursor &&
        this.lastRegionQuery.pageSize === regionQuery.pageSize &&
        this.lastRegionQuery.searchString === regionQuery.searchString
      )
        return

      this.lastRegionQuery = { ...regionQuery }

      if (!appendResult) this.jobsSubject.next(undefined)
      const regionId = regionQuery.region ? regionQuery.region.id : ''
      this.wordpressService
        .regionQuery(regionQuery.searchString, regionId, regionQuery.startCursor, regionQuery.pageSize)
        .subscribe((jobs) => {
          this.isFirstSearch = false
          this.regionQueryResultsSubject.next(jobs)
          this.jobsSubject.next(jobs)
          this.tracker.trackSiteSearch(regionQuery.searchString, regionId, jobs.length)
        })
    })
  }

  clearRegionQueryResults(): void {
    this.regionQueryResultsSubject.next([])
  }

  // Updates the regionQuery params.
  updateRegionQuery(regionQuery: RegionQuery): void {
    this.regionQuerySubject.next(regionQuery)
  }

  setQueryRegionByLabel(regionLabel: string): void {
    this.$regions
      .pipe(
        take(1),
        switchMap((regions) => {
          if (regions && regions.length > 0) {
            if (regionLabel !== DEFAULT_REGION) {
              return from(regions).pipe(
                filter((region) => region.label === regionLabel),
                take(1)
              )
            } else {
              return of(regions[0])
            }
          } else {
            return EMPTY
          }
        })
      )
      .subscribe((region) => {
        this.$regionQuery.pipe(take(1)).subscribe((regionQuery) => {
          this.regionQuerySubject.next({
            ...regionQuery,
            region
          })
          this.defautRegionUpdateSubject.next(true)
        })
      })
  }
}
