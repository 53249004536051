import { Inject, Injectable, InjectionToken, PLATFORM_ID } from "@angular/core";
import { Meta, Title } from '@angular/platform-browser'
import { SEOData } from '../../router/route-data'
import { isPlatformBrowser, isPlatformServer } from '@angular/common'
import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  fallbackTitle: string
  fallbackDescription: string
  fallbackImage: string
  fallbackUrl: string
  fallbackImageWidth: string
  fallbackImageHeight: string

  constructor(
    private title: Title,
    private meta: Meta,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Record<string, unknown>>
  ) {
    this.fallbackTitle = 'Der Jobbote - Regionale Stellenangebote in Mecklenburg-Vorpommern und Berlin-Brandenburg'
    this.fallbackUrl = this.getBaseUrl()
    this.fallbackDescription =
      'Finde jetzt aktuelle Jobs in Mecklenburg-Vorpommern und Berlin-Brandenburg! Egal ob Ausbildung, ' +
      'Arbeitsplatz für Quereinsteiger, Nebenjob oder Fach- und Führungskräfte, ' +
      'finde deinen Traumjob beim Jobboten.'
    this.fallbackImage = environment.API_URL + 'assets/img/Vorschaubild_Facebook.jpg'
    this.fallbackImageWidth = '1100'
    this.fallbackImageHeight = '1100'
  }

  getBaseUrl() {
    let baseUrl = environment.API_URL
    if (isPlatformBrowser(this.platformId) && window?.location?.origin) {
      baseUrl = `${window.location.origin}/`
    } else if (isPlatformServer(this.platformId)) {
      baseUrl = environment.API_URL
    }

    return baseUrl
  }

  updateData(data: SEOData) {
    let title = this.fallbackTitle,
      ogDescription = this.fallbackDescription,
      ogImageHeight = '',
      ogImageWidth = '',
      ogUrl = ''

    if (data?.title) {
      title = data.title
    }

    if (data?.ogDescription) ogDescription = data.ogDescription

    if (data?.ogImageWidth) ogImageWidth = data.ogImageWidth

    if (data?.ogImageHeight) ogImageHeight = data.ogImageHeight

    if (data?.ogUrl) ogUrl = data.ogUrl
    else if (data?.path) ogUrl = this.getBaseUrl() + data.path

    this.updateTitle(title)
    this.updateOgDescription(ogDescription)
    this.updateOgImage(ogImageWidth)
    this.updateOgImageHeight(ogImageHeight)
    this.updateOgImageWidth(ogImageWidth)

    if (ogUrl && ogUrl !== '') this.updateOgUrl(ogUrl)
    else this.meta.removeTag('property="og:url"')
  }

  updateStaticValue() {
    this.meta.updateTag({ property: 'robots', content: 'INDEX, FOLLOW' })
    this.meta.updateTag({ property: 'og:type', content: 'WEBSITE' })
  }

  updateTitle(title: string) {
    if (!title || title === '') {
      title = this.fallbackTitle
    }

    this.title.setTitle(title)
    this.meta.updateTag({ property: 'og:title', content: title })
  }

  updateOgDescription(description: string) {
    if (!description || description === '') {
      description = this.fallbackDescription
    }

    this.meta.updateTag({ name: 'description', content: description })
  }

  updateOgImage(image: string) {
    if (!image || image === '') {
      image = this.fallbackImage
    }

    this.meta.updateTag({ property: 'og:image', content: image })
  }

  updateOgImageWidth(imageWidth: string) {
    if (!imageWidth || imageWidth === '') {
      imageWidth = this.fallbackImageWidth
    }

    this.meta.updateTag({ property: 'og:imageWidth', content: imageWidth })
  }

  updateOgImageHeight(imageHeight: string) {
    if (!imageHeight || imageHeight === '') {
      imageHeight = this.fallbackImageHeight
    }

    this.meta.updateTag({ property: 'og:imageHeight', content: imageHeight })
  }

  updateOgUrl(url: string) {
    if (!url || url === '') {
      url = this.fallbackUrl
    }
    this.meta.updateTag({ property: 'og:url', content: url })
  }
}
