<nav class="d-block d-md-none navbar header navbar-light fixed-top">
  <div class="navbar-brand">
    <img width="150" height="50" alt="Logo Der Jobbote" ngSrc="/assets/img/logo_jobbote.svg"/>
  </div>
  <div class="d-flex flex-row"></div>
</nav>
<div class="row d-block d-md-none">
  <div class="boxesMobile left-container">
    <img priority alt="Küste von Mecklenburg-Vorpommern" class="resize-image" ngSrc="/assets/img/mecklenburg-vorpommern-muster-mobil.webp" height="721" width="750"  />
    <h1>Jetzt Traumjob finden in</h1>
    <a [routerLink]="['/', _homepageMv]" class="btn btn-primary upper-button">Mecklenburg-Vorpommern</a>
  </div>
  <div class="boxesMobile right-container">
    <img priority alt="Küste von Mecklenburg-Vorpommern" class="resize-image" ngSrc="/assets/img/berlin-brandenburg-muster-mobil.webp" height="721" width="750"  />
    <a [routerLink]="['/', _homepageBb]" class="btn btn-primary lower-button">Berlin & Brandenburg</a>
  </div>
</div>
<div class="row d-none d-md-block">
  <img class="logo" alt="Logo Der Jobbote" width="100" height="100" ngSrc="/assets/img/logo-jobbote-kreis.svg" />
  <a
    title="Mecklenburg-Vorpommern"
    [routerLink]="['/', _homepageMv]"
    class="boxes left-container"
  >
    <div class="position-relative">
      <h2>Finde Traumjobs in deiner Region mit dem Jobboten.</h2>
      <h1>Mecklenburg<br />Vorpommern</h1>
    </div>
  </a>
  <a
    title="Berlin-Brandenburg"
    [routerLink]="['/', _homepageBb]"
    class="boxes right-container"
  >
    <div class="position-relative">
      <h2>Finde Traumjobs in deiner Region mit dem Jobboten.</h2>
      <h1>Berlin &amp;<br />Brandenburg</h1>
    </div>
  </a>
</div>

<app-state-select-template-wrapper #stateSelect>
  <div class="statemodal">
    <img width="50" height="50" class="mb-3 modalLogo" alt="Logo Der Jobbote" ngSrc="/assets/img/logo-jobbote-kreis.svg" />
    <h1 class="mb-3">Jetzt Traumjob finden in</h1>
    <div class="buttons">
      <button type="button" class="btn btn-secondary mv" (click)="navigateToMv()">Mecklenburg-Vorpommern</button>
      <button type="button" class="btn btn-secondary bb" (click)="navigateToBb()">Berlin &amp; Brandenburg</button>
    </div>
  </div>
</app-state-select-template-wrapper>
