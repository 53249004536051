import { ChangeDetectorRef, Component, Inject, InjectionToken, PLATFORM_ID, ViewChild } from "@angular/core";
import { HeaderState, HeroState } from "./router/route-data";
import { Subscription } from "rxjs";
import { RouterService } from "./router/router.service";
import { LayoutService } from "./shared/services/layout.service";
import { StateSelectService } from "./shared/services/state-select.service";
import {
  StateSelectCompanyTemplateWrapperComponent
} from "./pages/public/company-home-page/state-select-company-template-wrapper.component";
import { JobService } from "./shared/services/job.service";
import { isPlatformBrowser } from "@angular/common";

@Component({
  selector: 'app-root',
  styleUrls: ['./app.component.scss'],
  template: `
    <div class="host bg-primary">
      <app-header *ngIf="showHeader"></app-header>
      <app-hero *ngIf="showHero"></app-hero>
      <div class="container-fluid wrapper bg-primary" [style.padding-top]="heroHeight">
        <router-outlet></router-outlet>
      </div>
      <app-footer></app-footer>
      <app-cookie-bar></app-cookie-bar>
    </div>
    <app-state-select-company-template-wrapper #stateSelect>
      <div class="statemodal">
        <h1>Bitte wählen Sie Ihre Region</h1>
        <div class="buttons">
          <button data-test="button-state-mv" type="button" class="btn btn-secondary mv" (click)="selectStateMv()">Mecklenburg-Vorpommern</button>
          <button type="button" class="btn btn-secondary bb" (click)="selectStateBb()">Berlin &amp; Brandenburg</button>
        </div>
      </div>
    </app-state-select-company-template-wrapper>
  `
})
export class AppComponent {
  @ViewChild('stateSelect') stateSelectTemplateWrapper: StateSelectCompanyTemplateWrapperComponent

  heroHeight = '0px'
  currentHeroState: HeroState
  currentHeaderState: HeaderState
  showHero = false
  showHeader = false
  heroStateSub: Subscription
  headerStateSub: Subscription
  heroHeightSub: Subscription
  stateSelectSub: Subscription

  constructor(
    private routerService: RouterService,
    private changeDetector: ChangeDetectorRef,
    private jobService: JobService,
    private layoutService: LayoutService,
    private stateSelectService: StateSelectService,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Record<string, unknown>>
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.stateSelectSub = this.stateSelectService.selectState.subscribe(() => {
        this.stateSelectTemplateWrapper.show()
      })
    }
    this.heroStateSub = this.routerService.$heroState.subscribe((heroState) => {
      this.currentHeroState = heroState
      this.showHero = heroState !== HeroState.none
    })
    this.headerStateSub = this.routerService.$headerState.subscribe((headerState) => {
      this.currentHeaderState = headerState
      this.showHeader = headerState !== HeaderState.none
    })
    this.heroHeightSub = this.layoutService.heroHeight$.subscribe((heroHeight) => {
      if (!heroHeight) return
      this.heroHeight = heroHeight
      this.changeDetector.detectChanges()
    })
  }

  ngOnDestroy(): void {
    if (this.stateSelectSub) {
      this.stateSelectSub.unsubscribe()
    }
    if (this.heroStateSub) {
      this.heroStateSub.unsubscribe()
    }
    if (this.headerStateSub) {
      this.headerStateSub.unsubscribe()
    }
    if (this.heroHeightSub) {
      this.heroHeightSub.unsubscribe()
    }
  }

  selectStateMv() {
    this.jobService.resetActiveRegion()
    this.stateSelectService.setStateMv()
    this.stateSelectTemplateWrapper.hide()
  }

  selectStateBb() {
    this.jobService.resetActiveRegion()
    this.stateSelectService.setStateBb()
    this.stateSelectTemplateWrapper.hide()
  }
}
