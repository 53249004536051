export const MOBILE_MAX_WIDTH = 992
export type State = 'mv' | 'bb';
export enum States {
  mv = 'mv',
  bb = 'bb'
}

export const EDITOR_LOCALS = {
  bold: 'Fett',
  italic: 'Kursiv',
  underline: 'Unterstrichen',
  h1: 'Überschrift 1',
  h2: 'Überschrift 2',
  ordered_list: 'Aufzählung',
  bullet_list: 'Stichpunkte',
  openInNewTab: 'In neuem Tab öffnen',
  heading: 'Überschrift',
  insertLink: 'Link einfügen',
  removeLink: 'Link entfernen',
  insert: 'Einfügen',
  remove: 'Entfernen',
}

// BookingFormState
export interface BookingFormState {
  companyContactForm?: CompanyContactForm
  userForm?: UserForm
  jobForm?: JobForm
  employmentFormIds?: string[]
}

export interface UserForm {
  email: string
  password: string
  passwordRepeat: string
}

// Booking types
////////////////

// Represents the booking object for a registered user/company.
export interface CompanyBooking {
  previewJob: Job
  postJob: PostJob
  headerImageRequiresUpload: boolean
  headerImageFile?: File
  headerImageUrl?: string
}

// Represents the additional booking object for a new user/company.
export interface UnknownBooking {
  userForm: UserForm
  companyContactForm: CompanyContactForm
  companyForm: CompanyForm
}

export interface JobLocationGoogle {
  lat: string
  lonig: string
  address: string
}

// job
//////

export interface Job {
  id: string
  title: string
  description: string
  industry: string
  urlSegment: string
  jobLocation: string
  jobLocationGoogle: string | JobLocationGoogle
  lastUpdatedDate: string
  lastUpdated: string
  headerImage: string
  headerImageWidth?: number
  headerImageHeight?: number
  radiospotAudioFile: string
  company: Company
  applicationUrl: string
  applicationEmail: string
  employmentForms: Taxonomy[]
  isHighlighted: boolean
  regions: Taxonomy[]
  state: Taxonomy
  keywords: string
  // new
  localLogo? // any
  localHeaderImage? // any
}

export interface DisplayJob extends Job {
  endDate: string
  jobLocationGoogle: JobLocationGoogle
}

export interface CompanyJob extends Job {
  headerImageId: string
  isEditable: string
  startDate: string
  endDate: string
  endDateDisplay?: string
  onAirStartDate: string
  onAirEndDate: string
  price: number
  broadcastingAreas: Taxonomy[]
  numberOfViews: number
  bookingDate: string
  highlightStartDate: string
  highlightEndDate: string
  bundle: Bundle
  radioBundle?: RadioBundle
  flatrate: Flatrate
  stateLabel: string
  companyFlatrateId: string
}

// GET: jobs/searchQuery --> Jobs für ALLE [JOB_OBJECT]
// GET: jobs/highlightAndRadioJobs --> Jobs für ALLE [JOB_OBJECT]
export interface JobListElement {
  id: string
  title: string
  companyName: string
  companyLogo?: string
  urlSegment: string
  jobLocation: string
  lastUpdated: string
  isOnAir: boolean
  isHighlighted: boolean
  isJobOfTheWeek: boolean
  keywords: string[]
}

// Change types_controller.php appropriate if you change this.
export interface PostJob {
  title: string
  description: string
  jobLocation: string
  startDate: string
  applicationUrl: string
  applicationEmail: string
  employmentFormIds: string[]
  regionId: string
  stateId: string
  keywords: string
  booking: Booking
  userEmail: string
  companyId: string
  headerImageId?: string
  headerImageUrl?: string
  companyName: string
  website: string
  logo: string
}

export interface UpdateJob {
  id: string
  title: string
  description: string
  jobLocation: string
  keywords: string
  applicationUrl: string
  applicationEmail: string
  employmentFormIds: string[]
  regionIds: string[]
  companyId: string
}

// company
//////////

export interface Company {
  id: string
  name: string
  website: string
  logo: string
  urlSegment: string
}

export interface UserCompany extends Company {
  customerId: string
}

export interface CompanyCompany extends Company {
  contacts: CompanyContact[]
  flatrates: CompanyFlatrate[]
  media: string[]
  customerId: string
}

export interface PostCompany {
  name: string
  website: string
  logo: string
}

export interface CompanyIdCompanySlug {
  companyId: string
  companySlug: string
}

// contact
//////////

export interface Contact {
  id: string
  companyId: string
  companyName: string
  salutation: string
  name: string
  firstName: string
  email: string
  phone: string
  street: string
  houseNumber: string
  zip: string
  city: string
  country: string
}

export interface CompanyContact extends Contact {
  isInvoice: boolean
  isContact: boolean
  createUser: boolean
}

export interface PostContact extends CompanyContactForm {
  companyId: string
  user: string
}

export interface UpdateContact extends PostContact {
  id: string
}

export interface CompanyContactForm {
  companyName: string
  salutation: string
  name: string
  firstName: string
  email: string
  phone: string
  street: string
  houseNumber: string
  zip: string
  city: string
  country: string
  isInvoice: boolean
  isContact: boolean
  createUser: boolean
}

export interface CompanyForm {
  name: string
  logo: string
  logoFile: File
  website: string
}

export interface JobForm {
  title: string
  description: string
  jobLocation: string
  startDate?: string
  stateId: string
  applicationUrl: string
  applicationEmail: string
  keywords: string
}

export interface UpdateCompany extends CompanyForm {
  id: string
}

export interface Image {
  id: string
  url: string
  file: string
  width: number
  height: number
}

export interface CompanyMedia {
  id: string
  media: Image[]
}

export interface UpdateCompanyMedia {
  id: string
  mediumId: string
}

// chatbot
//////////

export interface ChatbotData {
  position?: string
  location?: string
  firstName?: string
  name?: string
  phone?: string
  companyName?: string
  email?: string
  telephone?: string
  offerType?: string
  // date: string; // Today
}

// newsletter
//////////

export interface NewsletterData {
  email?: string
  // date: string; // Today
}

// custompage
/////////////

export interface CustomPage {
  id: string
  title: string
  urlSegment: string
  content: string
  apiElement: string
}

// Taxonomy
///////////

export interface Taxonomy {
  id: string
  label: string
  taxonomy: string
}


export type SelectableTaxonomy = Taxonomy & { selected?: boolean }

// JWT
//////

export interface JWTError {
  error: {
    code: string
    message: string
  }
}

export interface JWTUserResponse {
  token: string
  user_display_name: string
  user_email: string
  user_nicename: string
  companies: CompanyCompany[]
  companyId?: string
  companyCustomerId?: string
}

// user
///////

export interface PostUser {
  email: string
  password: string
}

export interface User {
  name: string
  email: string
  token: string
  companyId?: string
  companies?: Array<UserCompany>
  companyCustomerId: string
}

// Booking
//////////

export interface Booking {
  bookingType: BookingType
  bookingObject?: BookingObject
}

export type BookingObject = Bundle | SpecialOffer | Flatrate | CompanyFlatrate | RadioBundleWithPrice

export enum BookingType {
  edit = 'edit',
  // ------------------
  flatrate = 'flatrate',
  companyflatrate = 'companyflatrate',
  bundle = 'bundle',
  radio = 'radio',
  specialoffer = 'specialoffer'
}

// bundle
/////////

export interface Bundle {
  id: string
  label: string
  formattedLabel?: string
  subtitle: string
  price: number
  displayPrice: number
  originalPrice: number
  features: BundleFeature[]
  additionalInformation: string
  bookingDetail: string
  durationInDays: number
  optionalDurations: BundleOptionalDuration[]
  showInList: boolean
  hasHeaderImage: boolean
}

export interface BundleBroadcastArea {
  label: string
  price: number
  displayPrice: number
  mapSrc: string
}

export interface Radiostation {
  id: string
  broadcastareaMap: string
  infotext: string
  logoSrc: string
}

export interface RadioBundleWithPrice extends RadioBundle {
  price: number
  broadcastAreaLabel: string
}

export interface Feedback {
  id: string
  text: string
  customer: string
  location: string
}

export interface RadioBundle {
  id: string
  label: string
  additionalInfo: string
  radioStation: string
  radioStationLogoSrc: string
  broadcastAreas: BundleBroadcastArea[]
  totalListeners: number
  totalListenersInfo: number
  numberOfBroadcasts: string
  individualText1: string
  individualText2: string
  showInList: boolean
  bookingDetail: string
  bundle: Bundle
  flatrate: Flatrate
}

export interface BundleFeature {
  icon: string
  text: string
  information: string
}

export interface BundleOptionalDuration {
  durationLabel: string
  durationInDays: number
  price: number
  displayPrice: number
}

// flatrate
///////////

export interface Flatrate {
  id: string
  label: string
  showInList: boolean
  tooltip: string
  bundle: Bundle
  quota: number
  price: number
  displayPrice: number
  bookingDetail: string
  durationInDays: number
}

export interface CompanyFlatrate {
  id: string
  flatrate: Flatrate
  currentQuota: string
  startDate: string
  endDate: string
  endDateDisplay?: string
  companyId: string
  price: number
}

export interface PostCompanyFlatrate {
  flatrateId: string
  companyId: string
  jobId: string
}

// specialoffer
///////////////

export interface SpecialOffer {
  id: string
  label: string
  price: number
  type: string
  global: boolean
  customPageId: string
  ///////////////////
  flatrate?: Flatrate
  bundle?: Bundle
}

// company highlight
//////////////

export interface CompanyHighlight {
  id: string
  companyLogo: string
  companyName: string
  companyUrlSegment: string
}
