import { Component, Inject, InjectionToken, OnDestroy, PLATFORM_ID } from "@angular/core";
import {
  _contact,
  _aboutUs,
  _conditions,
  _privacyPolicy,
  _imprint,
  _companyInfo,
  _homepageBb,
  _homepageMv
} from 'src/app/router/route-data'
import { isPlatformBrowser } from '@angular/common'
import { StateSelectService } from '../../shared/services/state-select.service'
import { Subscription } from "rxjs";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnDestroy {
  _homepageBb = _homepageBb
  _homepageMv = _homepageMv
  _companyInfo = _companyInfo
  _contact = _contact
  _aboutUs = _aboutUs
  _privacyPolicy = _privacyPolicy
  _imprint = _imprint
  _conditions = _conditions

  isStateMv: boolean
  stateSelectSubscription: Subscription

  constructor(
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Record<string, unknown>>,
    private stateSelectService: StateSelectService
  ) {
    this.isStateMv = true

    // do not wait on subscriptions for server platform
    if (!isPlatformBrowser(this.platformId)) {
      return
    }
    this.stateSelectSubscription = this.stateSelectService.state$.subscribe((value) => {
      this.isStateMv = value !== 'bb'
    })
  }

  scrollToTop() {
    if (!isPlatformBrowser(this.platformId)) {
      return
    }

    window.scrollTo(0, 0)
  }

  ngOnDestroy(): void {
    if (this.stateSelectSubscription) {
      this.stateSelectSubscription.unsubscribe()
    }
  }
}
