<div *ngIf="!isRegistration" class="row mt-5">
  <div class="col-6 mx-auto text-center">
    <h4>Sie wurden erfolgreich abgemeldet.</h4>
    <br /><a class="btn btn-outline-secondary mt-2" [routerLink]="['/', _companyInfo]">Zu den Arbeitgeber-Informationen</a>
  </div>
</div>
<div *ngIf="isRegistration" class="row mt-5">
  <div class="col-4 mx-auto text-center">Geben Sie Ihre E-Mail-Adresse ein, um sich für den Newsletter anzumelden.</div>
</div>
<div *ngIf="isRegistration" class="row mt-3">
  <div class="col-4 mx-auto">
    <form [formGroup]="newsletterForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <input
          [ngClass]="{ invalid: (!email.valid && email.touched) || (!email.valid && evaluated) }"
          name=""
          class="form-control"
          formControlName="email"
          placeholder="E-Mail"
          autocomplete="email"
          type="email"
          id="email"
        />
        <div
          class="form-check mt-1 mb-3 py-1 rounded"
          [ngClass]="{
            invalid: (!privacyPolicyAccepted.valid && privacyPolicyAccepted.touched) || (!privacyPolicyAccepted.valid && evaluated)
          }"
        >
          <input formControlName="privacyPolicyAccepted" type="checkbox" class="form-check-input" id="privacyPolicyAccepted" />
          <label class="form-check-label" for="privacyPolicyAccepted">
            Ich stimme der
            <a target="_blank" [routerLink]="[_privacyPolicy]">Datenschutzerklärung</a>
            zu.
          </label>
        </div>
      </div>
      <button type="submit" class="btn btn-secondary btn-block">Anmelden</button>
    </form>
  </div>
</div>
