import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import * as routeData from './route-data'

// Public
/////////
import { StateSelectComponent } from '../pages/public/state-select/state-select.component'
import { NotFoundPageComponent } from '../pages/public/not-found-page/not-found-page.component'
import { AboutUsPageComponent } from '../pages/public/about-us-page/about-us-page.component'
import { ContactPageComponent } from '../pages/public/contact-page/contact-page.component'

// Test
//////
import { ErrorComponent } from '../pages/public/error.component'
import { NewsletterPageComponent } from '../pages/public/newsletter-page/newsletter-page.component'
import { DEFAULT_TITLE, REGION_TITLE } from "../modules/shared/shared.module";
import { CustomPageComponent } from "../shared/components/custom-page/custom-page.component";
import { States } from "../shared/data/types";

const appRoutes: routeData.AppRoute[] = [
  // Public
  /////////
  {
    path: ``,
    component: StateSelectComponent,
    data: {
      heroState: routeData.HeroState.none,
      headerState: routeData.HeaderState.none,
      seoData: {
        title: DEFAULT_TITLE
      },
      requiresNoStateSelection: true
    }
  },
  {
    path: `${routeData._stateselect}`,
    component: StateSelectComponent,
    data: {
      heroState: routeData.HeroState.none,
      headerState: routeData.HeaderState.none,
      seoData: {
        title: DEFAULT_TITLE,
        path: `${routeData._stateselect}`
      }
    }
  },
  {
    path: `${routeData._maintenance}`,
    component: ErrorComponent,
    data: {
      heroState: routeData.HeroState.none,
      headerState: routeData.HeaderState.showAllJobs,
      seoData: {
        title: DEFAULT_TITLE,
        path: `${routeData._maintenance}`
      }
    }
  },
  {
    path: 'newsletter',
    component: NewsletterPageComponent,
    data: {
      heroState: routeData.HeroState.none,
      headerState: routeData.HeaderState.showAllJobs
    }
  },
  {
    path: 'newsletter/abmeldung/user/:user',
    component: NewsletterPageComponent,
    data: {
      heroState: routeData.HeroState.none,
      headerState: routeData.HeaderState.showAllJobs
    }
  },
  {
    path: routeData._jobs,
    loadChildren: () => import('../modules/job/job.module').then(m => m.JobModule),
    data: {
      heroState: routeData.HeroState.jobSearchSmall,
      headerState: routeData.HeaderState.showAllJobs,
      seoData: {
        title: DEFAULT_TITLE,
        path: routeData._jobs
      }
    }
  },
   // START hardcoded region routes BB
  {
    path: routeData._jobsInBerlin,
    loadChildren: () => import('../modules/job/job.module').then(m => m.JobModule),
    data: {
      heroState: routeData.HeroState.jobSearchSmall,
      headerState: routeData.HeaderState.showAllJobs,
      regionLabel: routeData.region_routeLabelRouteMap._jobsInBerlin.label,
      state: States.bb,
      seoData: {
        title: REGION_TITLE + routeData.region_routeLabelRouteMap._jobsInBerlin.label,
        path: routeData._jobsInBerlin
      }
    }
  },
  {
    path: routeData._jobsInBrandenburgHavelland,
    loadChildren: () => import('../modules/job/job.module').then(m => m.JobModule),
    data: {
      heroState: routeData.HeroState.jobSearchSmall,
      headerState: routeData.HeaderState.showAllJobs,
      regionLabel: routeData.region_routeLabelRouteMap._jobsInBrandenburgHavelland.label,
      state: States.bb,
      seoData: {
        title: REGION_TITLE + routeData.region_routeLabelRouteMap._jobsInBrandenburgHavelland.label,
        path: routeData._jobsInBrandenburgHavelland
      }
    }
  },
  {
    path: routeData._jobsInElbeElsterOberspreewaldLausitzSpreeNeisse,
    loadChildren: () => import('../modules/job/job.module').then(m => m.JobModule),
    data: {
      heroState: routeData.HeroState.jobSearchSmall,
      headerState: routeData.HeaderState.showAllJobs,
      regionLabel: routeData.region_routeLabelRouteMap._jobsInElbeElsterOberspreewaldLausitzSpreeNeisse.label,
      state: States.bb,
      seoData: {
        title: REGION_TITLE + routeData.region_routeLabelRouteMap._jobsInElbeElsterOberspreewaldLausitzSpreeNeisse.label,
        path: routeData._jobsInElbeElsterOberspreewaldLausitzSpreeNeisse
      }
    }
  },
  {
    path: routeData._jobsInFrankfurtOderSpree,
    loadChildren: () => import('../modules/job/job.module').then(m => m.JobModule),
    data: {
      heroState: routeData.HeroState.jobSearchSmall,
      headerState: routeData.HeaderState.showAllJobs,
      regionLabel: routeData.region_routeLabelRouteMap._jobsInFrankfurtOderSpree.label,
      state: States.bb,
      seoData: {
        title: REGION_TITLE + routeData.region_routeLabelRouteMap._jobsInFrankfurtOderSpree.label,
        path: routeData._jobsInFrankfurtOderSpree
      }
    }
  },
  {
    path: routeData._jobsInPotsdamPotsdamMittelmark,
    loadChildren: () => import('../modules/job/job.module').then(m => m.JobModule),
    data: {
      heroState: routeData.HeroState.jobSearchSmall,
      headerState: routeData.HeaderState.showAllJobs,
      regionLabel: routeData.region_routeLabelRouteMap._jobsInPotsdamPotsdamMittelmark.label,
      state: States.bb,
      seoData: {
        title: REGION_TITLE + routeData.region_routeLabelRouteMap._jobsInPotsdamPotsdamMittelmark.label,
        path: routeData._jobsInPotsdamPotsdamMittelmark
      }
    }
  },
  {
    path: routeData._jobsInPrignitzOstprignitzRuppinOberhavel,
    loadChildren: () => import('../modules/job/job.module').then(m => m.JobModule),
    data: {
      heroState: routeData.HeroState.jobSearchSmall,
      headerState: routeData.HeaderState.showAllJobs,
      regionLabel: routeData.region_routeLabelRouteMap._jobsInPrignitzOstprignitzRuppinOberhavel.label,
      state: States.mv,
      seoData: {
        title: REGION_TITLE + routeData.region_routeLabelRouteMap._jobsInPrignitzOstprignitzRuppinOberhavel.label,
        path: routeData._jobsInPrignitzOstprignitzRuppinOberhavel
      }
    }
  },
  {
    path: routeData._jobsInTeltowFlaemingDahmeSpreewald,
    loadChildren: () => import('../modules/job/job.module').then(m => m.JobModule),
    data: {
      heroState: routeData.HeroState.jobSearchSmall,
      headerState: routeData.HeaderState.showAllJobs,
      regionLabel: routeData.region_routeLabelRouteMap._jobsInTeltowFlaemingDahmeSpreewald.label,
      state: States.mv,
      seoData: {
        title: REGION_TITLE + routeData.region_routeLabelRouteMap._jobsInTeltowFlaemingDahmeSpreewald.label,
        path: routeData._jobsInTeltowFlaemingDahmeSpreewald
      }
    }
  },
  {
    path: routeData._jobsInUckermarkBarnimMaerkischOberland,
    loadChildren: () => import('../modules/job/job.module').then(m => m.JobModule),
    data: {
      heroState: routeData.HeroState.jobSearchSmall,
      headerState: routeData.HeaderState.showAllJobs,
      regionLabel: routeData.region_routeLabelRouteMap._jobsInUckermarkBarnimMaerkischOberland.label,
      state: States.bb,
      seoData: {
        title: REGION_TITLE + routeData.region_routeLabelRouteMap._jobsInUckermarkBarnimMaerkischOberland.label,
        path: routeData._jobsInUckermarkBarnimMaerkischOberland
      }
    }
  },
  // START hardcoded region routes MV
  {
    path: routeData._jobsInLudwigslustParchim,
    loadChildren: () => import('../modules/job/job.module').then(m => m.JobModule),
    data: {
      heroState: routeData.HeroState.jobSearchSmall,
      headerState: routeData.HeaderState.showAllJobs,
      regionLabel: routeData.region_routeLabelRouteMap._jobsInLudwigslustParchim.label,
      state: States.mv,
      seoData: {
        title: REGION_TITLE + routeData.region_routeLabelRouteMap._jobsInLudwigslustParchim.label,
        path: routeData._jobsInLudwigslustParchim
      }
    }
  },
  {
    path: routeData._jobsInRostock,
    loadChildren: () => import('../modules/job/job.module').then(m => m.JobModule),
    data: {
      heroState: routeData.HeroState.jobSearchSmall,
      headerState: routeData.HeaderState.showAllJobs,
      regionLabel: routeData.region_routeLabelRouteMap._jobsInRostock.label,
      state: States.mv,
      seoData: {
        title: REGION_TITLE + routeData.region_routeLabelRouteMap._jobsInRostock.label,
        path: routeData._jobsInRostock
      }
    }
  },
  {
    path: routeData._jobsInLandkreisRostock,
    loadChildren: () => import('../modules/job/job.module').then(m => m.JobModule),
    data: {
      heroState: routeData.HeroState.jobSearchSmall,
      headerState: routeData.HeaderState.showAllJobs,
      regionLabel: routeData.region_routeLabelRouteMap._jobsInLandkreisRostock.label,
      state: States.mv,
      seoData: {
        title: REGION_TITLE + routeData.region_routeLabelRouteMap._jobsInLandkreisRostock.label,
        path: routeData._jobsInLandkreisRostock
      }
    }
  },
  {
    path: routeData._jobsInSchwerin,
    loadChildren: () => import('../modules/job/job.module').then(m => m.JobModule),
    data: {
      heroState: routeData.HeroState.jobSearchSmall,
      headerState: routeData.HeaderState.showAllJobs,
      regionLabel: routeData.region_routeLabelRouteMap._jobsInSchwerin.label,
      state: States.mv,
      seoData: {
        title: REGION_TITLE + routeData.region_routeLabelRouteMap._jobsInSchwerin.label,
        path: routeData._jobsInSchwerin
      }
    }
  },
  {
    path: routeData._jobsInMecklenburgischeSeenplatte,
    loadChildren: () => import('../modules/job/job.module').then(m => m.JobModule),
    data: {
      heroState: routeData.HeroState.jobSearchSmall,
      headerState: routeData.HeaderState.showAllJobs,
      regionLabel: routeData.region_routeLabelRouteMap._jobsInMecklenburgischeSeenplatte.label,
      state: States.mv,
      seoData: {
        title: REGION_TITLE + routeData.region_routeLabelRouteMap._jobsInMecklenburgischeSeenplatte.label,
        path: routeData._jobsInMecklenburgischeSeenplatte
      }
    }
  },
  {
    path: routeData._jobsNordwestmecklenburg,
    loadChildren: () => import('../modules/job/job.module').then(m => m.JobModule),
    data: {
      heroState: routeData.HeroState.jobSearchSmall,
      headerState: routeData.HeaderState.showAllJobs,
      regionLabel: routeData.region_routeLabelRouteMap._jobsNordwestmecklenburg.label,
      state: States.mv,
      seoData: {
        title: REGION_TITLE + routeData.region_routeLabelRouteMap._jobsNordwestmecklenburg.label,
        path: routeData._jobsNordwestmecklenburg
      }
    }
  },
  {
    path: routeData._jobsVorpommernGreifswald,
    loadChildren: () => import('../modules/job/job.module').then(m => m.JobModule),
    data: {
      heroState: routeData.HeroState.jobSearchSmall,
      headerState: routeData.HeaderState.showAllJobs,
      regionLabel: routeData.region_routeLabelRouteMap._jobsVorpommernGreifswald.label,
      state: States.mv,
      seoData: {
        title: REGION_TITLE + routeData.region_routeLabelRouteMap._jobsVorpommernGreifswald.label,
        path: routeData._jobsVorpommernGreifswald
      }
    }
  },
  {
    path: routeData._jobsVorpommernRuegen,
    loadChildren: () => import('../modules/job/job.module').then(m => m.JobModule),
    data: {
      heroState: routeData.HeroState.jobSearchSmall,
      headerState: routeData.HeaderState.showAllJobs,
      regionLabel: routeData.region_routeLabelRouteMap._jobsVorpommernRuegen.label,
      state: States.mv,
      seoData: {
        title: REGION_TITLE + routeData.region_routeLabelRouteMap._jobsVorpommernRuegen.label,
        path: routeData._jobsVorpommernRuegen
      }
    }
  },
  // END hardcoded region routes
  {
    path: routeData._jobs_id,
    loadChildren: () => import('../modules/job/job.module').then(m => m.JobModule),
    data: {
      heroState: routeData.HeroState.jobSearchSmall,
      headerState: routeData.HeaderState.showAllJobs
    }
  },
  {
    path: `${routeData._companyInfo}`,
    loadChildren: () => import('../modules/employer/employer.module').then(m => m.EmployerModule),
    data: {
      heroState: routeData.HeroState.companyBanner,
      headerState: routeData.HeaderState.showLogin,
      seoData: {
        title: 'Informationen für Arbeitgeber - Der Jobbote',
        path: `${routeData._companyInfo}`
      }
    }
  },
  {
    path: `${routeData._contact}`,
    component: ContactPageComponent,
    data: {
      heroState: routeData.HeroState.none,
      headerState: routeData.HeaderState.showAllJobs,
      seoData: {
        title: 'Kontakt - Der Jobbote',
        path: `${routeData._contact}`
      },
      requiresNoStateSelection: true
    }
  },
  {
    path: `${routeData._aboutUs}`,
    component: AboutUsPageComponent,
    data: {
      heroState: routeData.HeroState.none,
      headerState: routeData.HeaderState.showAllJobs,
      seoData: {
        title: 'Über uns - Der Jobbote',
        path: `${routeData._contact}`
      },
      requiresNoStateSelection: true
    }
  },
  {
    path: `${routeData._homepageMv}`,
    loadChildren: () => import('../modules/homeMv/home.module').then(m => m.HomeModuleMv),
    data: {
      heroState: routeData.HeroState.regionSelect,
      headerState: routeData.HeaderState.showAllJobs,
      seoData: {
        title: 'Der Jobbote - Regionale Stellenangebote in Mecklenburg-Vorpommern',
        path: `${routeData._homepageMv}`
      },
      requiresNoStateSelection: true
    }
  },
  {
    path: `${routeData._homepageBb}`,
    loadChildren: () => import('../modules/homeBb/home.module').then(m => m.HomeModuleBb),
    data: {
      heroState: routeData.HeroState.regionSelectBb,
      headerState: routeData.HeaderState.showAllJobs,
      seoData: {
        title: 'Der Jobbote - Regionale Stellenangebote in Berlin-Brandenburg',
        path: `${routeData._homepageBb}`
      },
      requiresNoStateSelection: true
    }
  },
  {
    path: `${routeData._companyInfo}`,
    loadChildren: () => import('../modules/company/company.module').then(m => m.CompanyModule),
    data: {
      heroState: routeData.HeroState.companyBanner,
      headerState: routeData.HeaderState.showLogin,
      seoData: {
        title: 'Informationen für Arbeitgeber - Der Jobbote',
        path: `${routeData._companyInfo}`
      }
    }
  },
  {
    path: `${routeData._bundles}`,
    loadChildren: () => import('../modules/booking/booking.module').then(m => m.BookingModule),
    data: {
      heroState: routeData.HeroState.none,
      headerState: routeData.HeaderState.faq,
      seoData: {
        title: 'Paket auswählen - Der Jobbote',
        path: `${routeData._bundles}`
      }
    }
  },

  // Company
  //////////
  {
    path: `${routeData._company}`,
    loadChildren: () => import('../modules/company/company.module').then(m => m.CompanyModule),
    data: {
      heroState: routeData.HeroState.none,
      headerState: routeData.HeaderState.company,
      seoData: {
        title: 'Kundenbereich - Der Jobbote'
      }
    }
  },
  {
    path: `${routeData._conditions}`,
    component: CustomPageComponent,
    data: {
      heroState: routeData.HeroState.none,
      headerState: routeData.HeaderState.showAllJobs,
      seoData: {
        title: 'Allgemeine Geschäftsbedingungen - Der Jobbote',
        path: `${routeData._conditions}`
      },
      requiresNoStateSelection: true
    }
  },
  {
    path: `${routeData._privacyPolicy}`,
    component: CustomPageComponent,
    data: {
      heroState: routeData.HeroState.none,
      headerState: routeData.HeaderState.showAllJobs,
      seoData: {
        title: 'Datenschutzerklärung - Der Jobbote',
        path: `${routeData._privacyPolicy}`
      },
      requiresNoStateSelection: true
    }
  },
  {
    path: `${routeData._imprint}`,
    component: CustomPageComponent,
    data: {
      heroState: routeData.HeroState.none,
      headerState: routeData.HeaderState.showAllJobs,
      seoData: {
        title: 'Impressum - Der Jobbote',
        path: `${routeData._imprint}`
      },
      requiresNoStateSelection: true
    }
  },
  {
    path: `${routeData._company}`,
    loadChildren: () => import('../modules/company/company.module').then(m => m.CompanyModule),
    data: {
      heroState: routeData.HeroState.none,
      headerState: routeData.HeaderState.company,
      seoData: {
        title: 'Kundenbereich - Der Jobbote'
      }
    }
  },

  // This should be the last route, even if it is a public route.
  // Fallback for non existent route (404)
  {
    path: '**',
    component: NotFoundPageComponent,
    data: {
      heroState: routeData.HeroState.none,
      headerState: routeData.HeaderState.showAllJobs,
      seoData: {
        title: 'Seite nicht gefunden - Der Jobbote'
      },
      requiresNoStateSelection: true
    }
  }
]

// This module is named AppRouterModule instead of RouterModule
// in order to not conflict with the Angular RouterModule (below).
@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled',
      initialNavigation: 'disabled',
      relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule]
})
export class AppRouterModule {}
