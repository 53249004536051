import {
  Component,
  EventEmitter,
  Inject,
  InjectionToken,
  Input,
  OnDestroy,
  Output,
  PLATFORM_ID
} from "@angular/core";
import {
  _companyInfo,
  _contact,
  _aboutUs,
  _privacyPolicy,
  _imprint,
  _conditions,
  _homepageBb, _homepageMv
} from "src/app/router/route-data";
import { isPlatformBrowser } from '@angular/common'
import { StateSelectService } from '../../services/state-select.service'
import { skip, Subscription } from "rxjs";
import { JobService } from "../../services/job.service";
import { take } from "rxjs/operators";
import { States } from "../../data/types";

@Component({
  selector: 'app-secondary-navigation',
  templateUrl: './secondary-navigation.component.html',
  styleUrls: ['./secondary-navigation.component.scss']
})
export class SecondaryNavigationComponent implements OnDestroy {
  @Output() navigationEvent: EventEmitter<boolean> = new EventEmitter<boolean>()

  private stateSelectSubscription: Subscription
  private _showSecondaryNavigation: boolean
  private interactionCountdown = 1
  userInteraction = false
  isRegionMv = true

  @Input() set showSecondaryNavigation(value: boolean) {
    this._showSecondaryNavigation = value
    if (this.interactionCountdown === 0) {
      this.userInteraction = true
    } else {
      --this.interactionCountdown
    }
  }

  get showSecondaryNavigation(): boolean {
    return this._showSecondaryNavigation
  }

  _companyInfo = _companyInfo
  _contact = _contact
  _aboutUs = _aboutUs
  _privacyPolicy = _privacyPolicy
  _imprint = _imprint
  _conditions = _conditions

  constructor(
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Record<string, unknown>>,
    private jobService: JobService,
    public stateSelectService: StateSelectService
  ) {
    this.stateSelectSubscription = this.stateSelectService.state$.subscribe((value) => {
      this.isRegionMv = value !== States.bb;
    });
  }

  onNavigationClick(): void {
    this.navigationEvent.emit(true)
    this.scrollToTop()
  }

  changeState() {
    this.onNavigationClick()

    this.jobService.$regions.pipe(skip(1), take(1)).subscribe(
      () => {
        this.jobService.resetActiveRegion()
      }
    )

    if (!this.isRegionMv) {
      this.stateSelectService.setStateMv()
    } else {
      this.stateSelectService.setStateBb()
    }
  }

  scrollToTop() {
    if (!isPlatformBrowser(this.platformId)) {
      return
    }

    window.scrollTo(0, 0)
  }

  ngOnDestroy(): void {
    if (this.stateSelectSubscription) {
      this.stateSelectSubscription.unsubscribe()
    }
  }

  protected readonly _homepageBb = _homepageBb;
  protected readonly _homepageMv = _homepageMv;
}
