import {
  Component,
  EventEmitter,
  Inject,
  InjectionToken,
  Input,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID
} from "@angular/core";
import {
  _company,
  _contact,
  _contacts,
  _imprint,
  _privacyPolicy,
  _edit,
  _media, _select, _companyInfo
} from "src/app/router/route-data";
import { UserService } from 'src/app/shared/services/user/user.service'
import { User } from 'src/app/shared/data/types'
import { isPlatformBrowser } from '@angular/common'
import { Router } from '@angular/router'
import { StateSelectService } from '../../services/state-select.service'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-company-navigation',
  templateUrl: './company-navigation.component.html',
  styleUrls: ['./company-navigation.component.scss']
})
export class CompanyNavigationComponent implements OnInit, OnDestroy {
  @Output() navigationEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  private _showCompanyNavigation: boolean;
  private interactionCountdown: number;

  @Input() set showCompanyNavigation(value: boolean) {
    this._showCompanyNavigation = value;
    if(this.interactionCountdown == 0) {
      this.userInteraction = true;
    } else {
      --this.interactionCountdown
    }
  }

  get showCompanyNavigation(): boolean {
    return this._showCompanyNavigation;
  }

  userInteraction: boolean
  _company = _company
  _contact = _contact
  _contacts = _contacts
  _select = _select
  _edit = _edit
  _media = _media
  _imprint = _imprint
  _privacyPolicy = _privacyPolicy

  user: User
  userHasMultipleCompanies: boolean
  private stateSelectSubscription: Subscription
  public nextRegion: string

  constructor(
    private userService: UserService,
    private router: Router,
    private stateSelectService: StateSelectService,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Record<string, unknown>>
  ) {
    this.nextRegion = 'Berlin-Brandenburg'
    this.userHasMultipleCompanies = false
    this.interactionCountdown = 1
    this.userInteraction = false
  }

  changeState() {
    this.scrollToTop()

    if (this.stateSelectService.isInitialized && this.stateSelectService.getState() !== 'mv') {
      this.stateSelectService.setStateMv()
      if (this.router.url === '/berlin-brandenburg') {
        this.router.navigate([`/mecklenburg-vorpommern`])
      }
    } else {
      this.stateSelectService.setStateBb()
      if (this.router.url === '/mecklenburg-vorpommern') {
        this.router.navigate([`/berlin-brandenburg`])
      }
    }
  }

  onNavigationClick(): void {
    this.navigationEvent.emit(true);
    this.scrollToTop()
  }

  scrollToTop() {
    if (!isPlatformBrowser(this.platformId)) {
      return
    }

    window.scrollTo(0, 0)
  }

  ngOnInit() {
    this.stateSelectSubscription = this.stateSelectService.state$.subscribe((value) => {
      if (!this.stateSelectService.isInitialized) {
        return
      }

      if (value !== 'mv') {
        this.nextRegion = 'Mecklenburg-Vorpommern'
      } else {
        this.nextRegion = 'Berlin-Brandenburg'
      }
    })

    this.userService.$user.subscribe((user) => {
      this.user = user
      this.userHasMultipleCompanies = user?.companies?.length > 1
    })
  }

  logout() {
    this.userService.logout()
    this.router.navigate(['/', _companyInfo])
  }

  ngOnDestroy(): void {
    if (this.stateSelectSubscription) {
      this.stateSelectSubscription.unsubscribe()
    }
  }

}
