import { Component, OnInit, OnDestroy } from '@angular/core'
import { UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms'
import { _privacyPolicy } from '../../../router/route-data'
import { WordpressService } from 'src/app/shared/services/wordpress.service'
import { UserService } from '../../../shared/services/user/user.service'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit, OnDestroy {
  _privacyPolicy = _privacyPolicy

  evaluated = false
  submitSuccessful = false
  isSubmitting = false

  constructor(private formBuilder: UntypedFormBuilder, private wordpressService: WordpressService, private userService: UserService) {}

  contactForm = this.formBuilder.group({
    name: ['', Validators.required],
    email: new UntypedFormControl(
      '',
      Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')])
    ),
    company: [''],
    telephone: [''],
    subject: [''],
    message: ['', Validators.required],
    captcha: ['', [Validators.required, Validators.min(2), Validators.max(2)]],
    privacyPolicyAccepted: [false, Validators.requiredTrue]
  })

  get name() {
    return this.contactForm.get('name')
  }
  get email() {
    return this.contactForm.get('email')
  }
  get company() {
    return this.contactForm.get('company')
  }
  get telephone() {
    return this.contactForm.get('telephone')
  }
  get message() {
    return this.contactForm.get('message')
  }
  get captcha() {
    return this.contactForm.get('captcha')
  }
  get privacyPolicyAccepted() {
    return this.contactForm.get('privacyPolicyAccepted')
  }

  userSub: Subscription

  ngOnInit() {
    this.userSub = this.userService.$user.subscribe((user) => {
      if (user && user.token) {
        this.wordpressService.getCompanyCompanyById(user.companyId).subscribe((companyCompany) => {
          if (companyCompany.customerId && companyCompany.customerId) {
            if (companyCompany && companyCompany.contacts && companyCompany.contacts.length > 0) {
              // set company contact data if available
              for (let i = 0; i < companyCompany.contacts.length; i++) {
                const contact = companyCompany.contacts[i]
                if (contact.isContact) {
                  this.name.setValue(contact.firstName + ' ' + contact.name)
                  this.email.setValue(contact.email)
                  this.company.setValue(companyCompany.name)
                  this.telephone.setValue(contact.phone)
                }
              }
            }

            if (!this.message.value) {
              this.message.setValue('Kundennummer: ' + companyCompany.customerId)
            }
          }
        })
      }
    })
  }

  ngOnDestroy(): void {
    if (this.userSub) this.userSub.unsubscribe()
  }

  onSubmit() {
    this.evaluated = true
    if (this.contactForm.valid) {
      this.isSubmitting = true
      this.wordpressService.contactForm(this.contactForm.value).subscribe(() => {
        this.submitSuccessful = true
        this.isSubmitting = false
      })
    }
  }
}
